import { Suspense, lazy, useEffect } from "react";

import { Typography } from "../../library";
import type { Category } from "../../pages/Home";
import SearchBar, { SearchBarCtaType } from "../../shared/SearchBar";
import { SearchSource, modals } from "../../utils/enums";
import { trackViewHomepage } from "../../utils/tracking";

import useShowModal from "../../hooks/useShowModal";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import CategoriesPage from "../Category/CategoriesPage";
import LandingPageSection from "./HomepageComponents/LandingPageSection";
import ScheduleDemoSection from "./HomepageComponents/ScheduleDemoSection";
import SolutionSection from "./HomepageComponents/SolutionSection";
import StatisticsSection from "./HomepageComponents/StatisticsSection";
import TeamSection from "./HomepageComponents/TeamSection";
import TestimonialSection from "./HomepageComponents/TestimonialSection";
import ValuePropSection from "./HomepageComponents/ValuePropSection";
import WordCarousel from "./HomepageComponents/WordCarousel";

const HOMEPAGE_PRODUCT_LIST = [
  "office furniture",
  "outdoor lighting",
  "IT consulting",
  "anything",
];

const Hero = lazy(() => import("../Hero"));

export default function HomePage({
  data: { categories },
  isSSR,
}: HomePageProps) {
  const showModal = useShowModal(modals.SEARCH);

  const headline = "Find suppliers with cooperative contracts for";
  const tagline =
    "Buy what you need faster from 50,000+ suppliers on 100,000+ contracts from cooperatives, states, and local agencies.";

  useEffect(
    () =>
      trackViewHomepage({
        layout: "home",
      }),
    []
  );

  function showSearchModal() {
    showModal({
      source: SearchSource.HOMEPAGE_SOURCE_STR,
    });
  }

  return (
    <div>
      <div className="flex flex-row cp-page-container xl:px-0 m-auto items-center py-16 xl:py-0 md:pt-20 md:pt-24">
        <div className="home-left-container flex flex-col w-full xl:w-1/2 md:w-[580px]">
          <Typography
            variant="display"
            size="sm"
            color="brand.boldest.enabled"
            className="text-cp-headline-lg md:text-cp-display-lg xl:text-cp-display-xl font-normal"
          >
            {headline}
          </Typography>
          <WordCarousel
            wordList={HOMEPAGE_PRODUCT_LIST}
            textClass="font-homeHeader text-cp-headline-lg md:text-cp-display-lg  xl:text-cp-display-xl text-cp-lapis-500"
          />
          <div className="hidden md:flex justify-start my-6">
            <SearchBar
              theme={SearchBarThemes.DARK}
              submitCta={SearchBarCtaType.TEXT}
              searchSource={SearchSource.HOMEPAGE_SOURCE_STR}
              cbOnEmptyQuery={() => {
                showSearchModal();
              }}
              isSSR={isSSR}
              disambiguate
            />
          </div>
          <Typography
            variant="headline"
            size="sm"
            color="brand.bold"
            className="md:mt-4 mt-2 max-w-xl"
          >
            {tagline}
          </Typography>
        </div>
        <Suspense fallback={<div className="h-[700px]" />}>
          <Hero />
        </Suspense>
      </div>
      <ValuePropSection search={showSearchModal} />
      <div className="hidden md:block">
        <CategoriesPage categories={categories} />
      </div>
      <TestimonialSection />
      <StatisticsSection search={showSearchModal} />
      <SolutionSection />
      <TeamSection />
      <ScheduleDemoSection />
      <LandingPageSection />
    </div>
  );
}

interface HomePageProps {
  data: {
    categories: Category[];
  };
  isSSR: boolean;
}
