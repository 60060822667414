import clsx from "clsx";
import type { ReactNode } from "react";
import { Typography } from "../../../library";
import ContractDocumentsFilter from "./ContractDocumentsFilter";
import ExpirationFilter from "./ExpirationFilter";
import FilterListButtons from "./FilterListButtons";
import { OtherFilters } from "./OtherFilters";
import SourcesFilter from "./SourcesFilter";
import SupplierLocationFilter from "./SupplierLocationFilter";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";
import { useExpirationFilterProps } from "./useExpirationFilterProps";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";

function FilterSection({
  title,
  children,
  border = true,
}: { title: string; children: ReactNode; border?: boolean }) {
  return (
    <div
      className={clsx("grid gap-2", {
        "border-solid border-cp-neutral-20 border-t pt-4": border,
      })}
    >
      <Typography variant="headline" size="xs" emphasis className="mb-2">
        {title}
      </Typography>
      {children}
    </div>
  );
}

interface AllFiltersProps {
  onFilterChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
  onShowResults: () => void;
  showLessThan6Months?: boolean;
}

export default function AllFilters({
  onFilterChange,
  onToggleFilter,
  filters,
  onShowResults,
  showLessThan6Months,
}: AllFiltersProps) {
  const resetFilters = useResetFiltersAndSearch();
  const expirationFilterProps = useExpirationFilterProps({
    showLessThan6Months,
    onChange: onFilterChange,
    filters,
    onToggleFilter,
  });

  return (
    <div className="grid gap-6">
      <FilterSection title="Contract source" border={false}>
        <SourcesFilter onChange={onFilterChange} />
      </FilterSection>
      <FilterSection title="Supplier location">
        <SupplierLocationFilter
          filters={filters}
          onChange={onFilterChange}
          onToggleFilter={onToggleFilter}
        />
      </FilterSection>
      <FilterSection title="Expiration date">
        <ExpirationFilter {...expirationFilterProps} />
      </FilterSection>
      <FilterSection title="Contract documents">
        <ContractDocumentsFilter
          filters={filters}
          onChange={onFilterChange}
          onToggleFilter={onToggleFilter}
        />
      </FilterSection>
      <FilterSection title="Additional preferences">
        <OtherFilters
          filters={filters}
          onChange={onFilterChange}
          onToggleFilter={onToggleFilter}
        />
      </FilterSection>
      <FilterListButtons onShowResults={onShowResults} onReset={resetFilters} />
    </div>
  );
}
