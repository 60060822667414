import { Transition, TransitionChild } from "@headlessui/react";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { purchasePathsAgencyAtom } from "../../jotai/purchasePaths";
import { Typography } from "../../library";
import {
  LOADING_INTERVAL,
  LOADING_STEPS,
  LOADING_STEPS_ORDER,
  type PurchasePathLoadingSteps,
} from "./constants";

const LOADING_BASE_STYLES = "transition duration-300";
function LoadingStep({
  show,
  name,
  agencyName,
}: { show: boolean; name: PurchasePathLoadingSteps; agencyName: string }) {
  const { text, icon } = LOADING_STEPS[name];

  const loadingText = typeof text === "function" ? text(agencyName) : text;

  return (
    <div className="absolute top-0 flex flex-col items-center">
      <Transition show={show}>
        <TransitionChild>
          <Typography
            className={clsx(
              LOADING_BASE_STYLES,
              // Closed state starts invisible and below axis
              "data-[closed]:opacity-0 data-[closed]:translate-y-[2rem]",
              // Leaving animation moves above axis
              "data-[leave]:data-[closed]:translate-y-[-2rem]"
            )}
            color="neutral.boldest.enabled"
          >
            {loadingText}
          </Typography>
        </TransitionChild>
        <TransitionChild>
          <img
            src={icon}
            alt="Loading illustration"
            className={clsx(
              LOADING_BASE_STYLES,
              "mt-6 w-36 h-36 object-scale-down",
              // Closed state starts invisible
              "data-[closed]:opacity-0"
            )}
          />
        </TransitionChild>
      </Transition>
    </div>
  );
}

export default function PurchasePathsLoading() {
  const { purchaseAgencyDisplayName } = useAtomValue(purchasePathsAgencyAtom);
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prev) => (prev + 1) % LOADING_STEPS_ORDER.length);
    }, LOADING_INTERVAL);

    return () => clearInterval(timer);
  }, []);

  if (!purchaseAgencyDisplayName) return null;

  return (
    <div className="flex flex-col gap-16 items-center">
      <Typography size="md" variant="display" color="neutral.boldest.enabled">
        Looking for the best purchasing method...
      </Typography>
      <div className="relative w-full flex justify-center">
        {LOADING_STEPS_ORDER.map((stepName, index) => (
          <LoadingStep
            key={stepName}
            name={stepName}
            show={index === step}
            agencyName={purchaseAgencyDisplayName}
          />
        ))}
      </div>
    </div>
  );
}
