import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import { v4 as uuidv4 } from "uuid";

import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
} from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import {
  contractSearchAnalyticsParamsState,
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  disallowedSupplierSearchQueryState,
  redirectSearchResponseDataState,
  searchResultTypeState,
  supplierSearchResponseDataState,
  topSupplierContractCardState,
} from "../jotai/search";
import { getParam, hasWindow } from "../utils";
import { MAX_RESULTS } from "../utils/constants";
import { SearchActions } from "../utils/enums";
import { handleError as handleGeneratedError } from "../utils/generatedApi";

import { captureException } from "@sentry/browser";
import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService } from "../generated";
import { isContractHit } from "../shared/SearchPage/utils";
import { getProBoost } from "./search/utils";
import useHasMaxWalkthroughSearches from "./useHasMaxWalkthroughSearches";
import useTrackContractSearch from "./useTrackContractSearch";

export function shouldUpdateRequestID(
  oldSearchParams: SearchParams,
  newSearchParams: SearchParams
) {
  // Only update the request ID if we have meaningfully changed the
  // params. The only fields that count this as a new request for analytics
  // are query, zip, and filters.
  const paramsToInclude = ["query", "zip", "filters", "collapseBySupplier"];

  const oldComparedParams = _pick(oldSearchParams, paramsToInclude);
  const newComparedParams = _pick(newSearchParams, paramsToInclude);

  if (!oldSearchParams || _isEqual(oldComparedParams, newComparedParams)) {
    return false;
  }
  return true;
}

export interface SearchContractsAndSuppliersArgs {
  requestID: string;
  set: Setter;
  query?: string;
  filters?: string[];
  zip?: string;
  embedSourceEntityId?: string;
  collapseBySupplier?: boolean;
  parentRequestID?: string;
  originalAmbiguousQuery?: Maybe<string>;
  selectedDisambiguationOption?: Maybe<string>;
  skipSupplierSearch?: boolean;
}

const searchContractsAndSuppliers = async ({
  requestID,
  set,
  query,
  filters,
  zip,
  embedSourceEntityId,
  collapseBySupplier,
  parentRequestID,
  originalAmbiguousQuery,
  selectedDisambiguationOption,
  skipSupplierSearch,
}: SearchContractsAndSuppliersArgs) => {
  set(contractSearchIsLoadingState, true);
  set(contractSearchResponseDataState, null);
  set(supplierSearchResponseDataState, null);
  set(redirectSearchResponseDataState, null);
  set(topSupplierContractCardState, null);
  set(searchResultTypeState, collapseBySupplier ? "supplier" : "contract");
  try {
    const response = await ApiService.apiV1ContractSearchCreate({
      query: query || "",
      filters,
      zip,
      embedSourceEntityId,
      // When filtering clientside, retrieve results that includes all
      // strong matches in one go to offer the largest bucket of filterable
      // results.
      numResultsPerPage: MAX_RESULTS,
      page: 0,
      requestID,
      parentRequestID,
      proBoost: getProBoost("proBoost"),
      evProBoost: getProBoost("evProBoost"),
      ocProBoost: getProBoost("ocProBoost"),
      targetProBoost: getProBoost("targetProBoost"),
      productBoost: getProductBoost(),
      rankStrategy: getParam("rankStrategy"),
      collapseBySupplier,
      originalAmbiguousQuery,
      selectedDisambiguationOption,
      skipSupplierSearch,
    });
    set(contractSearchResponseDataState, response);
    set(supplierSearchResponseDataState, response.supplierData);
    set(redirectSearchResponseDataState, response.redirectData);
    set(contractSearchIsLoadingState, false);
    return response;
  } catch (err) {
    handleGeneratedError(err);
    set(contractSearchResponseDataState, {
      contractData: {
        numAllResults: 0,
        numShowingResults: 0,
        numStrongResults: 0,
        results: [],
      },
      metadata: null,
      params: null,
      agencyData: null,
      supplierData: null,
      redirectData: null,
      prioritizedEntityMatchData: null,
    });
    set(supplierSearchResponseDataState, {
      supplierData: { suppliers: [], fuzzyResults: [] },
      supplierMatchingAliases: {},
    });
    set(contractSearchIsLoadingState, false);
    return null;
  }
};

function getProductBoost() {
  const rawProductBoost = hasWindow() && getParam("productBoost");
  if (!rawProductBoost) {
    return null;
  }
  const parsedProductBoost = Number.parseInt(rawProductBoost);
  if (Number.isNaN(parsedProductBoost)) {
    return null;
  }
  return parsedProductBoost;
}

export default function useSearchContractWithParams() {
  const trackContractSearch = useTrackContractSearch();
  const [, incrementSearches] = useHasMaxWalkthroughSearches();

  const search = useAtomCallback(
    useCallback(
      async (
        get: Getter,
        set: Setter,
        { newParams = {}, action = SearchActions.SEARCH }: SearchOptions
      ) => {
        const searchParams = get(contractSearchParamsState);
        const analyticsParams = get(contractSearchAnalyticsParamsState);
        const searchResultType = get(searchResultTypeState);

        const params = formatSearchPageParams(searchParams);
        const combinedParams = {
          ...params,
          collapseBySupplier: searchResultType === "supplier",
          ...newParams,
        } as ContractSearchParams;

        const validation = validateSearchParams(combinedParams);
        if (!validation.valid) {
          set(contractSearchResponseDataState, {
            contractData: {
              numAllResults: 0,
              numShowingResults: 0,
              numStrongResults: 0,
              results: [],
            },
            metadata: null,
            params: null,
            agencyData: null,
            supplierData: null,
            redirectData: null,
            prioritizedEntityMatchData: null,
            errorMessage: validation.errorMessage || "",
          });
          return;
        }
        const {
          query,
          zip,
          page,
          filters,
          embedSourceEntityId,
          searchSource,
          collapseBySupplier,
          originalAmbiguousQuery,
          selectedDisambiguationOption,
        } = combinedParams;

        // If we are searching contracts only, we don't want to update the query state -
        // this supports the supplier redirect experience.
        const updatedSearchQuery =
          action === SearchActions.SEARCH_CONTRACTS_ONLY
            ? searchParams.query
            : query || "";

        // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
        // PLEASE COORDINATE IF THESE CHANGE.
        const updatedParams: SearchParams = {
          query: updatedSearchQuery,
          zip: zip || "",
          page: page?.toString() || "",
          filters: filters?.join(";") || "",
        };

        const disallowedSupplierSearchQuery = get(
          disallowedSupplierSearchQueryState
        );

        // For supplier redirects, we want to skip updating the supplier data when:
        const skipSupplierSearch =
          // - the user clicked "search for <supplier name> contracts" and wants a contract-only search
          action === SearchActions.SEARCH_CONTRACTS_ONLY ||
          // - the user is applying filters on a contract-only search
          query === disallowedSupplierSearchQuery;

        // Once we have a non-skipped search, we should reset all the redirect state.
        // This state is managed and used in useHideSupplierSearch.ts.
        if (!skipSupplierSearch) {
          set(disallowedSupplierSearchQueryState, "");
        }

        // Update search params local state, which binds to URL param changes.
        if (!_isEqual(searchParams, updatedParams)) {
          set(contractSearchParamsState, updatedParams);
        }
        const requestID = shouldUpdateRequestID(searchParams, {
          ...updatedParams,
          collapseBySupplier,
        })
          ? uuidv4()
          : analyticsParams.requestID || uuidv4();

        // If we change the request id, track this as a new search.
        if (requestID !== analyticsParams.requestID) {
          incrementSearches();
          set(contractSearchAnalyticsParamsState, {
            requestID,
            searchSource: searchSource || "",
          });
        }

        const multiResponse = await searchContractsAndSuppliers({
          requestID,
          set,
          query,
          filters,
          zip,
          embedSourceEntityId,
          collapseBySupplier,
          parentRequestID: undefined,
          originalAmbiguousQuery,
          selectedDisambiguationOption,
          skipSupplierSearch,
        });

        // Track the original search.
        if (multiResponse) {
          const numSupplierHits =
            multiResponse.supplierData?.supplierData?.suppliers?.length || 0;
          trackContractSearch(get, set, {
            data: multiResponse,
            action,
            numSupplierHits,
            collapseBySupplier,
          });
        }

        // Shuffle top supplier results into their own new section if the top supplier and top contract match.
        if (
          !skipSupplierSearch &&
          multiResponse &&
          multiResponse.supplierData &&
          multiResponse.supplierData.supplierData?.suppliers?.length &&
          multiResponse.contractData?.results?.length
        ) {
          const topSupplier =
            multiResponse.supplierData.supplierData?.suppliers[0];
          const topContract = multiResponse.contractData?.results[0];
          if (
            topSupplier?.supplier.id === topContract?.supplierId &&
            !isContractHit(topContract)
          ) {
            set(topSupplierContractCardState, topContract);
            set(contractSearchResponseDataState, {
              ...multiResponse,
              contractData: {
                ...multiResponse.contractData,
                results: multiResponse.contractData.results.slice(1),
              },
            });
          }
        }

        // Filter out the existing supplier match from the redirect results.
        // This is so that the supplier match doesn't show up twice in the search results.
        // TODO: add filter to vespa instead https://app.shortcut.com/coprocure/story/26426
        if (
          multiResponse?.supplierData?.supplierData?.suppliers?.length &&
          multiResponse.redirectData?.results?.length
        ) {
          const supplierId =
            multiResponse.supplierData.supplierData.suppliers[0].supplier.id;
          set(redirectSearchResponseDataState, {
            ...multiResponse.redirectData,
            results: multiResponse.redirectData.results.filter(
              (result) => result.supplierId !== supplierId
            ),
          });
        }

        // After all the redirects have been handled, update the search params state
        if (multiResponse) {
          let params: SearchParams;
          params = {
            query: updatedSearchQuery,
            zip: multiResponse.params?.zip || "",
            page: multiResponse.params?.page?.toString() || "",
            filters: multiResponse.params?.filters?.join(";") || "",
          };
          if (multiResponse.params?.embedSourceEntityId) {
            params = {
              ...params,
              embedSourceEntityId: multiResponse.params.embedSourceEntityId,
            };
          }
          set(contractSearchParamsState, params);

          if (!multiResponse.params?.requestId) {
            // Track if the contract search response does not have a request ID - this is a problem
            captureException(
              new Error("Contract search response does not have a request ID"),
              {
                extra: { multiResponse },
              }
            );
          }

          set(contractSearchAnalyticsParamsState, {
            // We want clicks to be associated with the redirected search if we have it.
            requestID:
              multiResponse.params?.childRequestId ||
              multiResponse.params?.requestId ||
              requestID,
            searchSource: searchSource || "",
          });
        }
      },
      [trackContractSearch, incrementSearches]
    )
  );

  return search;
}
