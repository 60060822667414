import type { ReactElement } from "react";

import { type FrontendTag, VariantEnum } from "../../generated";
import {
  dateWithinThirtyDays,
  isDateInPast,
  parseDate,
} from "../../utils/date";
import { getSupplierTagOrder } from "../../utils/tags";
import Tag, { TagVariants } from "../Tag";
import TransparentTag from "../TransparentTag";
import type { TagType } from "./types";

interface GetTagsOptions {
  contractTagData?: FrontendTag[];
  supplierTagData?: FrontendTag[];
  matchesDiversity?: boolean;
  supplier_closed?: boolean;
  expiration_ts?: Maybe<number>;
  expiration_date?: Maybe<string | Date>;
  matchTier?: string;
  blaState?: string;
  blaType?: string;
  blaRank?: number;
  filterScore?: number;
  semanticScore?: number;
  proBoost?: Maybe<number>;
  productBoost?: Maybe<number>;
  geoBoost?: Maybe<number>;
  scaledBoost?: Maybe<number>;
  contractQualityBoost?: Maybe<number>;
  localSupplierBoost?: Maybe<number>;
  supplierConfirmedStatesServedBoost?: Maybe<number>;
  supplierConfirmedAgencyTypesServedBoost?: Maybe<number>;
  supplierResponsivenessBoost?: Maybe<number>;
  isCooperative?: boolean | null;
  excludeDocumentTags?: boolean;
  excludeSupportTags?: boolean;
  isDebug?: boolean;
  transparent?: boolean;
  size?: "sm" | "md";
  supplierTagOrder?: TagVariants[];
}

interface GetTagsResponse {
  tags: ReactElement[];
  contractTagElements: ReactElement[];
  supplierTagElements: ReactElement[];
  tagVariantList: string[];
  tagCopyList: string[];
}

export const TYPE_TO_VARIANT: {
  [key in VariantEnum]: TagVariants;
} = {
  [VariantEnum.PRICING_DOC]: TagVariants.PRICING_DOC,
  [VariantEnum.NOT_AVAILABLE_LOCATION]: TagVariants.OUT_OF_AREA,
  [VariantEnum.UNSUPPORTED_BY_SUPPLIER]: TagVariants.UNSUPPORTED,
  [VariantEnum.UNSUPPORTED_BY_BLA]: TagVariants.UNSUPPORTED,
  [VariantEnum.UNSUPPORTED_BY_SOURCE]: TagVariants.UNSUPPORTED,
  [VariantEnum.VERIFIED_CONTACT]: TagVariants.VERIFIED_CONTACT,
  [VariantEnum.LOCAL_SUPPLIER]: TagVariants.LOCAL_SUPPLIER,
  [VariantEnum.PRO_SUPPLIER]: TagVariants.PRO_SUPPLIER,
  [VariantEnum.YEARS_IN_BUSINESS]: TagVariants.YEARS_IN_BUSINESS,
  [VariantEnum.MAX_DELIVERY_TIME]: TagVariants.MAX_DELIVERY_TIME,
  [VariantEnum.SERVICE_PROVIDED]: TagVariants.SERVICE_PROVIDED,
  [VariantEnum.MADE_IN_USA]: TagVariants.MADE_IN_USA,
  [VariantEnum.BEST_PRICE_GUARANTEE]: TagVariants.BEST_PRICE_GUARANTEE,
  [VariantEnum.FREE_CONSULTATION]: TagVariants.FREE_CONSULTATION,
  [VariantEnum.FAMILY_OWNED]: TagVariants.FAMILY_OWNED,
  [VariantEnum.GOVERNMENT_DISCOUNT]: TagVariants.GOVERNMENT_DISCOUNT,
  [VariantEnum.SOCIAL_PROOF]: TagVariants.SOCIAL_PROOF,
  [VariantEnum.RECENT_RESPONSE]: TagVariants.RECENT_RESPONSE,
  [VariantEnum.NOT_PRIVATE_COOP_MEMBER]: TagVariants.NOT_PRIVATE_COOP_MEMBER,
  [VariantEnum.LOCAL_TO_CITY]: TagVariants.LOCAL_TO_CITY,
  [VariantEnum.RANKED_FIRST_ON_SOLICITATION]:
    TagVariants.RANKED_FIRST_ON_SOLICITATION,
  [VariantEnum.CONTRACT_LABEL]: TagVariants.CONTRACT_LABEL,
  [VariantEnum.AUTHORIZED_RESELLER]: TagVariants.AUTHORIZED_RESELLER,
  [VariantEnum.LOCAL_AUTHORIZED_RESELLER]:
    TagVariants.LOCAL_AUTHORIZED_RESELLER,
  [VariantEnum.YOUR_CONTRACT]: TagVariants.YOUR_CONTRACT,
};

export const DOCUMENT_TAG_TYPES = [VariantEnum.PRICING_DOC];
export const UNSUPPORTED_TAG_TYPES = [
  VariantEnum.UNSUPPORTED_BY_BLA,
  VariantEnum.UNSUPPORTED_BY_SOURCE,
  VariantEnum.UNSUPPORTED_BY_SUPPLIER,
];

export const LOCAL_SUPPLIER_TOOLTIP_TEXT =
  "This supplier is located less than 100 miles from you";

export const VARIANT_TO_TOOLTIP: { [key in TagVariants]?: string } = {
  [TagVariants.NOT_PRIVATE_COOP_MEMBER]:
    "Contracts for members only unless given approval",
  [TagVariants.LOCAL_SUPPLIER]: LOCAL_SUPPLIER_TOOLTIP_TEXT,
  [TagVariants.LOCAL_TO_CITY]: LOCAL_SUPPLIER_TOOLTIP_TEXT,
};

function isVariantEnum(
  variant: VariantEnum | TagVariants
): variant is VariantEnum {
  return Object.values(VariantEnum).includes(variant as VariantEnum);
}

export function filterAndSortTagsByOrder(
  tagData: FrontendTag[],
  supplierTagOrder: TagVariants[]
): FrontendTag[] {
  return (
    tagData
      ?.filter(({ variant }) => {
        const variantType = TYPE_TO_VARIANT[variant];
        return supplierTagOrder.includes(variantType);
      })
      .sort((a, b) => {
        const indexA = supplierTagOrder.indexOf(TYPE_TO_VARIANT[a.variant]);
        const indexB = supplierTagOrder.indexOf(TYPE_TO_VARIANT[b.variant]);
        return indexA - indexB;
      }) || []
  );
}

export default function getTags({
  contractTagData,
  supplierTagData,
  matchesDiversity,
  supplier_closed,
  expiration_ts,
  expiration_date,
  blaState,
  blaType,
  blaRank,
  matchTier,
  filterScore,
  semanticScore,
  proBoost,
  productBoost,
  geoBoost,
  scaledBoost,
  contractQualityBoost,
  localSupplierBoost,
  supplierConfirmedStatesServedBoost,
  supplierConfirmedAgencyTypesServedBoost,
  supplierResponsivenessBoost,
  isCooperative = true,
  excludeDocumentTags = false,
  excludeSupportTags = false,
  isDebug = false,
  transparent = false,
  size = "sm",
  supplierTagOrder = getSupplierTagOrder(),
}: GetTagsOptions): GetTagsResponse {
  // These are passed to trackContractClick and trackSerpClick.
  const contractTagVariants: string[] = [];
  const contractTagCopy: string[] = [];
  const supplierTagVariants: string[] = [];
  const supplierTagCopy: string[] = [];

  const contractTags: TagType[] = [];
  const supplierTags: TagType[] = [];

  function appendSupplierTag({
    copy,
    variant,
    tooltip,
  }: {
    copy: string;
    variant: TagVariants;
    variantOverride?: {
      textColor?: string;
      bgColorClass?: string;
      iconColor?: string;
    };
    tooltip?: string;
  }) {
    supplierTags.push({
      text: copy,
      variant,
      // pass tooltip argument or check TAG_TYPE_TO_TOOLTIP for default
      tooltip: tooltip || VARIANT_TO_TOOLTIP[variant],
    });
    supplierTagVariants.push(variant);
    supplierTagCopy.push(copy);
  }

  function appendContractTag({
    copy,
    variant,
    tooltip,
  }: {
    copy: string;
    variant: TagVariants;
    tooltip?: string;
  }) {
    if (isVariantEnum(variant)) {
      if (
        (excludeDocumentTags && DOCUMENT_TAG_TYPES.includes(variant)) ||
        (excludeSupportTags && UNSUPPORTED_TAG_TYPES.includes(variant))
      ) {
        return;
      }
    }
    contractTags.push({
      text: copy,
      variant,
      // pass tooltip argument or check TAG_TYPE_TO_TOOLTIP for default
      tooltip: tooltip || VARIANT_TO_TOOLTIP[variant],
    });
    contractTagVariants.push(variant);
    contractTagCopy.push(copy);
  }

  // Handle supplier tags with ordering
  const sortedSupplierTagData = supplierTagData
    ? filterAndSortTagsByOrder(supplierTagData, supplierTagOrder)
    : [];

  sortedSupplierTagData.forEach(({ copy, variant }) => {
    const variantType = TYPE_TO_VARIANT[variant];

    if (copy && variantType) {
      appendSupplierTag({
        copy,
        variant: variantType,
      });
    }
  });

  if (matchesDiversity) {
    appendSupplierTag({
      copy: "Diversity certifications",
      variant: TagVariants.DIVERSITY,
    });
  }

  // Handle contract tags
  contractTagData?.forEach(({ copy, variant }) => {
    if (copy && TYPE_TO_VARIANT[variant]) {
      appendContractTag({
        copy,
        variant: TYPE_TO_VARIANT[variant],
      });
    }
  });

  if (
    isCooperative === false &&
    // Only show non-coop tag for contracts that aren't yours
    !contractTagData
      ?.map(({ variant }) => variant)
      .includes(VariantEnum.YOUR_CONTRACT)
  ) {
    appendContractTag({
      copy: "Non-cooperative",
      variant: TagVariants.NON_COOPERATIVE,
      tooltip:
        "Only members of your entity can see your non-cooperative contracts",
    });
  }

  if (supplier_closed || expiration_date || expiration_ts) {
    // Add expiration tag if relevant
    const isExpired =
      supplier_closed ||
      isDateInPast(expiration_date || parseDate(expiration_ts));

    if (isExpired) {
      appendContractTag({
        copy: "Expired",
        variant: TagVariants.EXPIRED,
      });
    } else if (dateWithinThirtyDays(expiration_date || expiration_ts)) {
      appendContractTag({
        copy: "Expiring soon",
        variant: TagVariants.WARNING,
      });
    }
  }
  if (isDebug) {
    if (matchTier) {
      appendContractTag({
        copy: `Match Tier: ${matchTier}`,
        variant: TagVariants.DEBUG_MATCH,
      });
      if (semanticScore) {
        appendContractTag({
          copy: `Score: ${filterScore} (${(semanticScore * 100).toLocaleString(
            undefined,
            {
              maximumSignificantDigits: 4,
            }
          )})`,
          variant: TagVariants.DEBUG_INFO,
        });
      }
      if (scaledBoost) {
        const boostContext = [
          proBoost && `pro ${proBoost.toFixed(2)}`,
          geoBoost && `geo ${geoBoost.toFixed(2)}`,
          productBoost && `product ${productBoost}`,
          contractQualityBoost &&
            `contract quality ${contractQualityBoost.toFixed(2)}`,
          localSupplierBoost &&
            `local supplier ${localSupplierBoost.toFixed(2)}`,
          supplierConfirmedStatesServedBoost &&
            `confirmed states served ${supplierConfirmedStatesServedBoost.toFixed(2)}`,
          supplierConfirmedAgencyTypesServedBoost &&
            `confirmed agency types served ${supplierConfirmedStatesServedBoost?.toFixed(2)}`,
          supplierResponsivenessBoost &&
            `responsiveness ${supplierResponsivenessBoost.toFixed(2)}`,
        ]
          .filter((v) => !!v)
          .join(", ");
        const boostText = `${scaledBoost.toFixed(2)} (${boostContext})`;

        appendContractTag({
          copy: `Boost: ${boostText}`,
          variant: TagVariants.DEBUG_INFO,
        });
      }
    }
    if (blaType) {
      const blaRankText = `${blaType} ${
        blaState ? `in ${blaState}` : ""
      } (Rank: ${blaRank})`;
      appendContractTag({
        copy: blaRankText,
        variant: TagVariants.DEBUG_BLA,
      });
    }
    if (proBoost && proBoost > 0) {
      appendContractTag({
        copy: "Pro Supplier",
        variant: TagVariants.DEBUG_PRO,
      });
    }
  }

  const tagLimit = isDebug ? undefined : 3;

  const tagVariantList = [
    ...contractTagVariants.slice(0, tagLimit),
    ...supplierTagVariants.slice(0, tagLimit),
  ];

  const tagCopyList = [
    ...contractTagCopy.slice(0, tagLimit),
    ...supplierTagCopy.slice(0, tagLimit),
  ];

  const Component = transparent ? TransparentTag : Tag;
  const contractTagElements = contractTags.slice(0, tagLimit).map((tag, ix) => (
    // biome-ignore lint/suspicious/noArrayIndexKey: Since tags are static, this is ok
    <Component key={ix} size={size} {...tag}>
      {tag.text}
    </Component>
  ));

  const supplierTagElements = supplierTags.slice(0, tagLimit).map((tag, ix) => (
    // biome-ignore lint/suspicious/noArrayIndexKey: Since tags are static, this is ok
    <Component key={contractTagElements.length + ix} size={size} {...tag}>
      {tag.text}
    </Component>
  ));

  const tags = [...contractTagElements, ...supplierTagElements];

  return {
    tags,
    contractTagElements,
    supplierTagElements,
    tagVariantList,
    tagCopyList,
  };
}
