import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import useDiversityPreferences from "../../hooks/useDiversityPreferences";
import {
  buyerProfileState,
  isAuthenticatedState,
  userInitializedState,
  userZipState,
} from "../../jotai/user";
import { Badge, Logo, LogoSizes, PageSection, Typography } from "../../library";
import ContentNotFound from "../../shared/ContentNotFound/ContentNotFound";
import SearchBar from "../../shared/SearchBar";
import { ContentTypes } from "../../shared/types";
import { getParams, hasWindow, isDistributedAgencyType } from "../../utils";
import { SearchActions, SearchFilter, SearchSource } from "../../utils/enums";
import { trackHeapEvent, trackSearchFilterToggle } from "../../utils/tracking";
import { getCountsForTracking } from "../ContractSearch/utils";

import { MembershipTypeEnum } from "../../generated";
import useFilteredSearchResults from "../../hooks/search/useFilteredSearchResults";
import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useLandingPageSearchContractWithParams from "../../hooks/useLandingPageSearchContractWithParams";
import {
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
} from "../../jotai/search";
import { matchedSearchResultCountState } from "../../jotai/searchFilters";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import SearchFilterHeader from "../../shared/SearchPage/SearchResults/SearchFilterHeader";
import { formatNumber } from "../../utils/format";
import ScheduleDemoSection from "../Home/HomepageComponents/ScheduleDemoSection";
import type { PageData } from "./LandingPage";
import ResultsSection from "./ResultSection";
import {
  getLogoForBLA,
  trackContractClickLandingPageSearch,
  trackContractSearchLandingPageSearch,
} from "./helpers";

const DEFAULT_BLA_FILTER = [
  SearchFilter.INCLUDE_NON_COOP,
  SearchFilter.INCLUDE_EXPIRED,
  SearchFilter.IS_NOT_LOCATION_RELEVANT,
  SearchFilter.INCLUDE_UNUSABLE,
];

interface LoggedInLandingPageProps {
  page: PageData;
  isSSR: boolean;
}
export function LoggedInLandingPage({
  page: {
    source_value,
    slug,
    title,
    display_name,
    search_params,
    total,
    buyer_lead_agency_id,
    membership_type,
    subtitle,
  },
  isSSR = false,
}: LoggedInLandingPageProps) {
  const buyerProfile = useAtomValue(buyerProfileState);
  const landingPagePath = hasWindow() ? window.location.pathname : "";
  const diversityPreferences = useDiversityPreferences();
  const [trackingCounts, setTrackingCounts] = useState({
    firstPageStrongMatchCount: 0,
    firstPagePossibleMatchCount: 0,
    firstPageSemanticMatchCount: 0,
  });
  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const requestID = contractResponseData?.params?.requestId || "";
  const contractSearchIsLoading = useAtomValue(contractSearchIsLoadingState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const isInitialized = useAtomValue(userInitializedState);
  const userZip = useAtomValue(userZipState);

  const [searchParams, setSearchParams] = useAtom(contractSearchParamsState);
  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  useBindPageParamDict(searchParams, setSearchParams);
  const searchContractWithParams = useLandingPageSearchContractWithParams(slug);

  const waitForSearch = useMemo(
    () => isAuthenticated && !isInitialized,
    [isAuthenticated, isInitialized]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on first render
  useEffect(() => {
    if (waitForSearch) return;

    const urlParams = getParams();
    const initialFilters = (
      searchParams?.filters
        ? searchParams.filters.split(";").filter((f) => !!f)
        : []
    ) as SearchFilter[];
    setFilters(initialFilters || DEFAULT_BLA_FILTER);
    searchContractWithParams({
      newParams: {
        query: (urlParams.query as string) || "",
        filters: initialFilters || DEFAULT_BLA_FILTER,
        landingPageSlug: slug,
        embedSourceEntityId: buyer_lead_agency_id,
        requestID,
        zip: userZip,
      },
      action: SearchActions.SEARCH,
    });
  }, [waitForSearch]);

  useEffect(() => {
    setFilters(
      (searchParams.filters?.split(";").filter((f) => !!f) ||
        []) as SearchFilter[]
    );
  }, [searchParams.filters]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on first render
  useEffect(() => {
    trackHeapEvent("view-bla-page", {
      buyerLeadAgencyId: buyer_lead_agency_id,
    });
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when the page value changes.
  useEffect(() => {
    const {
      strongMatchesCount,
      possibleMatchesCount,
      scopeMatchesCount,
      supplierNameMatchesCount,
      semanticMatchesCount,
    } = getCountsForTracking(contractResponseData?.contractData?.results);
    const action = SearchActions.SEARCH;
    const firstPageTrackingCounts = {
      firstPageStrongMatchCount: strongMatchesCount,
      firstPagePossibleMatchCount: possibleMatchesCount,
      firstPageSemanticMatchCount: semanticMatchesCount,
    };
    setTrackingCounts(firstPageTrackingCounts);
    trackContractSearchLandingPageSearch({
      query: searchParams?.query as string,
      total,
      page: 0,
      searchParams: search_params,
      trackingCounts: firstPageTrackingCounts,
      scopeMatchesCount,
      supplierNameMatchesCount,
      buyerProfile,
      requestID,
      action,
    });
  }, []); // TODO: this needs to depend on params?

  const matchedSearchResultCount = useAtomValue(matchedSearchResultCountState);

  const filteredHits = useFilteredSearchResults({
    results: contractResponseData?.contractData?.results || [],
    filterLowSimilarityResults: false,
    filterUnresponsiveContacts: false,
    excludeAgency: { id: buyer_lead_agency_id, name: display_name },
  });

  const matchedHits = filteredHits.slice(0, matchedSearchResultCount);
  const logoUrl = getLogoForBLA(buyer_lead_agency_id);

  return (
    <div className="pt-12">
      <PageSection className="xl:py-0">
        <div className="w-full md:max-w-screen-xl flex flex-col gap-1 md:gap-2 lg:w-2/3">
          <div className="flex gap-2 md:gap-4">
            {logoUrl && <Logo imageSrc={logoUrl} size={LogoSizes.XL} />}
            <Typography
              variant="display"
              size="sm"
              color="brand.boldest.enabled"
              className="font-normal"
              component="h1"
            >
              {title}
            </Typography>
          </div>
          <Typography
            component="div"
            color="subtle"
            size="sm"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: This prop is the quick-n-dirty way to render an html string provided by the backend.
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          {membership_type &&
            membership_type === MembershipTypeEnum.PRIVATE_DISTRIBUTED && (
              <Typography
                size="sm"
                color="neutral.boldest.enabled"
                className="mt-2"
              >
                <Badge
                  size="sm"
                  Icon={WarningAmberOutlinedIcon}
                  tooltip="Contracts for members only unless given approval"
                >
                  Approval required for contracts
                </Badge>
              </Typography>
            )}
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
            className="mt-4"
            emphasis
          >
            Search{" "}
            {membership_type === MembershipTypeEnum.NONE &&
              `${formatNumber(total)} `}
            {display_name}
            {isDistributedAgencyType(membership_type) ? " member " : " "}
            contracts
          </Typography>
          <div className="flex">
            <SearchBar
              className="bla-search-bar mt-2"
              theme={SearchBarThemes.DARK}
              isLocationRelevant={false}
              searchSource={SearchSource.LANDING_PAGE_ENTITY_SEARCH}
              isSSR={isSSR}
              searchUrl={landingPagePath}
              disableAutocomplete
              cbOnEmptyQuery={() => {
                window.open(landingPagePath, "_self");
              }}
              onSearch={searchContractWithParams}
              disambiguate
            />
          </div>
        </div>
        <div className="my-5">
          <SearchFilterHeader
            filters={filters}
            setFilters={setFilters}
            onFilterChange={(params) => {
              trackSearchFilterToggle({
                ...params,
                query: searchParams?.query,
                requestID,
              });
            }}
            onSearch={searchContractWithParams}
            showLessThan6Months
            showResultTypeToggle={false}
          />
        </div>
      </PageSection>
      {contractResponseData?.contractData?.results.length === 0 && (
        <ContentNotFound contentType={ContentTypes.SEARCH_RESULTS} />
      )}
      <PageSection>
        <ResultsSection
          className="pb-6"
          query={(searchParams?.query as string) || ""}
          hits={matchedHits}
          isLoading={contractSearchIsLoading}
          trackContractClick={(hit) => {
            trackContractClickLandingPageSearch({
              hit,
              trackingCounts,
              query: searchParams?.query as string,
              sourceValue: source_value,
              requestID,
            });
          }}
          requestID={requestID}
          diversityPreferences={diversityPreferences}
        />
      </PageSection>
      <ScheduleDemoSection
        analyticsClassName="analytics-landingpage-scheduleDemo"
        headline="Need help finding a contract?"
        body="Tell us more about your search. Our team will help you find a contract you can use, and how your agency can use Pavilion."
      />
    </div>
  );
}
