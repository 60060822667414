import type { Getter, Setter } from "jotai";
import { useCallback } from "react";

import { useAtomCallback } from "jotai/utils";
import type { PurchaseOutput } from "../components/PurchasePaths/types";
import { ApiService } from "../generated";
import {
  purchasePathSupplierCountsAtom,
  purchasePathsResultsAtom,
} from "../jotai/purchasePaths";
import { handleError as handleGeneratedError } from "../utils/generatedApi";

const getSupplierCounts = async (query: string) => {
  const [coopContractsResponse, blaContractsResponse] = await Promise.all([
    ApiService.apiV1SummarizedBlaContractSearchCreate({
      query,
      restrictive: false,
    }),
    ApiService.apiV1SummarizedBlaContractSearchCreate({
      query,
      restrictive: true,
    }),
  ]);

  return {
    blaContractSuppliers: blaContractsResponse.supplierCount,
    coopContractSuppliers: coopContractsResponse.supplierCount,
  };
};

function getPurchaseOptions<T extends string>(
  entitySuppliers: number,
  coopSuppliers: number,
  prevResults: PurchaseOutput<T>
): { recommendedOptions: T[]; backupOptions: T[] } {
  const prevBackupOptions = prevResults.backupOptions ?? [];
  const prevRecommendedOptions = prevResults.recommendedOptions ?? [];

  let recommended: T[] = [...prevRecommendedOptions];
  // if no contract suppliers found, remove option from recommended
  if (entitySuppliers === 0) {
    recommended = recommended.filter((o) => o !== "ENTITY_CONTRACT") as T[];
  }
  if (coopSuppliers === 0) {
    recommended = recommended.filter(
      (o) => o !== "COOPERATIVE_CONTRACT"
    ) as T[];
  }

  // if no recommended options left, use first non-coop backup option
  if (!recommended.length) {
    const backup = prevBackupOptions.find(
      (o) => !["COOPERATIVE_CONTRACT", "ENTITY_CONTRACT"].includes(o)
    ) as T;
    if (backup) recommended = [backup];
  }

  if (!recommended) {
    // realistically this shouldn't happen
    return {
      recommendedOptions: prevResults.recommendedOptions,
      backupOptions: prevResults.backupOptions,
    };
  }

  // determine backup options
  const newBackupOptions = prevBackupOptions.filter(
    (o) => !recommended.includes(o)
  );

  const shouldIncludeCoop =
    !prevResults.forbiddenOptions.includes("COOPERATIVE_CONTRACT" as T) &&
    !recommended.includes("COOPERATIVE_CONTRACT" as T);

  const shouldIncludeEntity = !recommended.includes("ENTITY_CONTRACT" as T);

  if (shouldIncludeCoop) {
    newBackupOptions.unshift("COOPERATIVE_CONTRACT" as T);
  }
  if (shouldIncludeEntity) {
    newBackupOptions.unshift("ENTITY_CONTRACT" as T);
  }

  return { recommendedOptions: recommended, backupOptions: newBackupOptions };
}

export const usePurchasePathSearch = () => {
  const updatePurchasePathResults = useCallback(
    (
      get: Getter,
      set: Setter,
      entityContractSuppliers: number,
      coopContractSuppliers: number
    ) => {
      const prev = get(purchasePathsResultsAtom);
      if (!prev) return;

      const { recommendedOptions, backupOptions } = getPurchaseOptions(
        entityContractSuppliers,
        coopContractSuppliers,
        prev
      );

      set(purchasePathsResultsAtom, {
        ...prev,
        recommendedOptions,
        backupOptions,
      });
    },
    []
  );

  const search = useCallback(
    async (get: Getter, set: Setter, query: string) => {
      try {
        const { blaContractSuppliers, coopContractSuppliers } =
          await getSupplierCounts(query);

        set(purchasePathSupplierCountsAtom, {
          ENTITY_CONTRACT: blaContractSuppliers,
          COOPERATIVE_CONTRACT: coopContractSuppliers,
        });

        updatePurchasePathResults(
          get,
          set,
          blaContractSuppliers,
          coopContractSuppliers
        );
      } catch (err) {
        handleGeneratedError(err);
      }
    },
    [updatePurchasePathResults]
  );

  return useAtomCallback(search);
};
