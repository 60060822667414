import { useAtomValue } from "jotai";
import { contractSearchResponseDataState } from "../../../jotai/search";
import { numFiltersAppliedState } from "../../../jotai/searchFilters";
import { Typography } from "../../../library";
import { isDistributedAgencyType } from "../../../utils";

export default function OtherResultsHeaderText({ query }: { query: string }) {
  const contractSearchResponseData = useAtomValue(
    contractSearchResponseDataState
  );
  const numFiltersApplied = useAtomValue(numFiltersAppliedState);

  const filtersApplied = numFiltersApplied > 0;
  const agency = contractSearchResponseData?.agencyData?.agency;

  return (
    <div className="grid gap-2">
      <Typography variant="headline" emphasis color="brand.boldest.enabled">
        {filtersApplied
          ? `More contracts ${
              agency
                ? `from ${agency.name}${isDistributedAgencyType(agency.membershipType) ? " members" : ""}`
                : `for "${query}"`
            }`
          : "Other related contracts"}
      </Typography>
      <Typography color="neutral.bolder.enabled">
        {filtersApplied
          ? "We found some great contracts that are relevant to your query, but fall outside of your filters. To see more results, remove any filters you're not using."
          : "Here are some related options we found that might fit your needs."}
      </Typography>
    </div>
  );
}
