import { Typography } from "../../../library";
import {
  type EntityIntakeCategories,
  type EntityIntakeData,
  type EntityIntakePathDetails,
  PurchaseCategoryEnum,
  type PurchaseOutput,
} from "../types";
import { TaskLink } from "../utils";

const TEXTBOOKS_ADDENDUM =
  "{% if category == 'TEXTBOOKS' %} Textbooks can always be purchased directly since they're exempt from competitive solicitation requirements.{% endif %}";
const IT_ADDENDUM =
  "{% if category == 'IT' %} Any purchase of computer equipment, desktop printers, and/or web based software  programs, receives approval through MUNIS workflow by the IT Department.{% endif %}";
const COMMODITY_ADDENDUM =
  "{% if category == 'COMMODITY' %}You can start a request for quotes for goods/non-professional services between $10,000 and $200,000. {% endif %}";
const SERVICES_ADDENDUM =
  "{% if category == 'SERVICES' %}You can start a request for quotes for professional services up to $80,000. {% endif %}";

const CATEGORIES: EntityIntakeCategories = {
  [PurchaseCategoryEnum.COMMODITY]: "Goods or non-professional services",
  [PurchaseCategoryEnum.SERVICES]: "Professional services",
  [PurchaseCategoryEnum.CONSTRUCTION]:
    "Construction, architectural or engineering services",
  [PurchaseCategoryEnum.TEXTBOOKS]: "Textbooks",
  [PurchaseCategoryEnum.IT]: "IT",
};

const DEFAULT_TASKS = [
  {
    name: "identify",
    label: <Typography size="sm">Identify your need.</Typography>,
  },
  {
    name: "write",
    label: (
      <Typography size="sm">Write specifications for your need.</Typography>
    ),
  },
  {
    name: "check",
    label: (
      <Typography size="sm">
        Check if ACPS has an{" "}
        <TaskLink href="https://app.smartsheet.com/b/publish?EQBCT=205bbf66c2ef4978965615d49776ceaf">
          active contract
        </TaskLink>{" "}
        for the goods/services you need and download it if so.
      </Typography>
    ),
  },
];
const MUNIS_TASK = {
  name: "munis",
  label: (
    <Typography size="sm">
      Enter your requisition in MUNIS including any quotes obtained.
    </Typography>
  ),
};
const PAVILION_TASK = {
  name: "check-pavilion",
  label: (
    <Typography size="sm">
      Check Pavilion for cooperative contracts for the goods/services you need.
    </Typography>
  ),
};
function getITTasks(category: PurchaseCategoryEnum) {
  if (category !== PurchaseCategoryEnum.IT) return [];
  return [
    {
      name: "obtain",
      label: (
        <Typography size="sm">
          Obtain written approval from the IT department for any purchase of
          computer equipment, desktop printers, and/or web based software
          programs.
        </Typography>
      ),
    },
    {
      name: "determine",
      label: (
        <Typography size="sm">
          Determine if purchase requires a Data Privacy Agreement (DPA). DPAs
          are required if the vendor receives students data, including
          Personally Identifiable Information (PII), directory data as defined
          by the Family Educational Rights and Privacy Act (FERPA), and/or
          students log into the website and interact with content, complete
          assessments, or any other information which is non-public in nature.
          The vendor receives staff data beyond what is public information, but
          not if staff are interacting with software using only publicly
          available information (email, name, etc.).
        </Typography>
      ),
    },
    {
      name: "signed",
      label: (
        <Typography size="sm">
          If DPA is required,{" "}
          <TaskLink href="https://docs.google.com/spreadsheets/d/1hOc0uqvVORBmR3rrJaGBp4MdNQPn-Do6C1WDuVu_8jo/edit?usp=sharing">
            check here
          </TaskLink>
          to see if vendor has signed a DPA.
        </Typography>
      ),
    },
  ];
}

enum PathOption {
  COMPETITIVE_SOLICITATION = "COMPETITIVE_SOLICITATION",
  COOPERATIVE_CONTRACT = "COOPERATIVE_CONTRACT",
  ENTITY_CONTRACT = "ENTITY_CONTRACT",
  RFQ = "RFQ",
  SMALL_PURCHASE = "SMALL_PURCHASE",
}

const PATH_DETAILS: EntityIntakePathDetails<PathOption> = {
  [PathOption.SMALL_PURCHASE]: {
    headline: "Make a small purchase directly",
    description: `You can purchase directly from suppliers for goods or non-professional services below $10,000. Multiple quotes are encouraged for small purchases, but not required.${TEXTBOOKS_ADDENDUM}`,
    estimatedTime: "1-2 days (estimated)",
    cta: "View suppliers",
    getTasks: (category) => [
      ...DEFAULT_TASKS,
      {
        name: "request",
        label: (
          <Typography size="sm">
            Request 1+ quotes from suppliers via Pavilion.
          </Typography>
        ),
      },
      MUNIS_TASK,
      ...getITTasks(category),
    ],
  },
  [PathOption.RFQ]: {
    headline: "Start a request for quotes",
    description: `${COMMODITY_ADDENDUM}${SERVICES_ADDENDUM}An RFQ requires obtaining {% if category == 'COMMODITY' and budget.maxAmount <= 200000 %}3{% else %}4{% endif %} quotes from different suppliers. Please use the RFQ form here.`,
    estimatedTime: "Up to 21 business days",
    cta: "View suppliers",
    getTasks: (category, maxAmount) => [
      ...DEFAULT_TASKS,
      {
        name: "request",
        label: (
          <Typography size="sm">
            Request
            {category === PurchaseCategoryEnum.COMMODITY &&
            maxAmount !== null &&
            maxAmount <= 200000
              ? " 3 "
              : " 4 "}
            quotes from suppliers via Pavilion.
          </Typography>
        ),
      },
      MUNIS_TASK,
      ...getITTasks(category),
    ],
  },
  [PathOption.COOPERATIVE_CONTRACT]: {
    headline: "Use a cooperative contract",
    description: `You can skip the bidding process by using existing cooperative contracts that were competitively awarded by other entities and coops.${IT_ADDENDUM}`,
    estimatedTime: "Up to 7 business days",
    cta: "View suppliers with contracts",
    getTasks: (category) => [
      ...DEFAULT_TASKS,
      PAVILION_TASK,
      {
        name: "download-solicitation",
        label: (
          <Typography size="sm">Download cooperative solicitation.</Typography>
        ),
      },
      {
        name: "download-contract",
        label: (
          <Typography size="sm">Download cooperative contract.</Typography>
        ),
      },
      {
        name: "download-price",
        label: (
          <Typography size="sm">
            Download cooperative price list (if applicable).
          </Typography>
        ),
      },
      {
        name: "upload",
        label: (
          <Typography size="sm">
            Upload downloaded documents to{" "}
            <TaskLink href="https://app.smartsheet.com/b/form/da7136533f0b4033be00f3e8e72daad0">
              PSR portal
            </TaskLink>
            .
          </Typography>
        ),
      },
      {
        name: "munis",
        label: (
          <Typography size="sm">
            After a contract number has been issued, enter your requisition in
            MUNIS.
          </Typography>
        ),
      },
      ...getITTasks(category),
    ],
  },
  [PathOption.COMPETITIVE_SOLICITATION]: {
    headline: "Start a competitive solicitation",
    description:
      "You can start a competitive solicitation for {% if category == 'COMMODITY' %}purchases of goods/non-professional services over $200,000{% else %}purchases of professional services over $80,000{% endif %}",
    estimatedTime: "Up to 90 days",
    cta: "View suppliers",
    getTasks: (category) => [
      ...DEFAULT_TASKS,
      PAVILION_TASK,
      {
        name: "submit",
        label: (
          <Typography size="sm">
            If no cooperative options, submit a{" "}
            <TaskLink href="https://acps.instructure.com/courses/10485/files/7933180?wrap=1">
              Solicitation Request form
            </TaskLink>
            to the{" "}
            <TaskLink href="https://app.smartsheet.com/b/form/da7136533f0b4033be00f3e8e72daad0">
              PSR portal.
            </TaskLink>
          </Typography>
        ),
      },
      ...getITTasks(category),
    ],
  },
  [PathOption.ENTITY_CONTRACT]: {
    headline: "Use an existing ACPS contract",
    description:
      "You can check for an existing contract that matches your need.",
    cta: "Check for existing suppliers",
    getTasks: (category) => [
      ...DEFAULT_TASKS,
      MUNIS_TASK,
      ...getITTasks(category),
    ],
  },
};

const OUTPUTS: PurchaseOutput<PathOption>[] = [
  // Goods
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 10000,
    recommendedOptions: [PathOption.SMALL_PURCHASE],
    backupOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 30000,
    recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
    backupOptions: [PathOption.RFQ],
    forbiddenOptions: [],
  },
  // Differeng thresholds have differing # of RFQs required.
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 200000,
    recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
    backupOptions: [PathOption.RFQ],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.COMPETITIVE_SOLICITATION],
    forbiddenOptions: [],
  },
  // Services
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 80000,
    recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
    forbiddenOptions: [PathOption.RFQ],
    backupOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.COMPETITIVE_SOLICITATION],
    forbiddenOptions: [],
  },
  // Textbooks
  {
    applicableCategory: PurchaseCategoryEnum.TEXTBOOKS,
    thresholdAmount: null,
    recommendedOptions: [PathOption.SMALL_PURCHASE],
    backupOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COMPETITIVE_SOLICITATION,
      PathOption.SMALL_PURCHASE,
      PathOption.RFQ,
      PathOption.SMALL_PURCHASE,
    ],
    backupOptions: [],
    forbiddenOptions: [PathOption.COOPERATIVE_CONTRACT],
  },
  {
    applicableCategory: PurchaseCategoryEnum.IT,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
      PathOption.SMALL_PURCHASE,
      PathOption.COMPETITIVE_SOLICITATION,
      PathOption.RFQ,
    ],
    backupOptions: [],
    forbiddenOptions: [],
  },
];

const data: EntityIntakeData<PathOption> = {
  id: "c72a041d-2577-489a-affb-619a9d214361",
  slug: "acps",
  displayName: "Alexandria City Public Schools",
  questions: [],
  categories: CATEGORIES,
  outputs: OUTPUTS,
  pathDetails: PATH_DETAILS,
};

export default data;
