/**
 * A single instance of a supplier's details, with optional CTAs
 */
import type { ReactNode } from "react";

import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import { Avatar, AvatarSizes, Typography } from "../../library";
import type { LoginWallTriggers } from "../../utils/enums";

import ProductSection from "../../components/ContractSearch/ProductSection";
import { styleSearchResponseText } from "../../components/ContractSearch/utils";
import type { MatchResult, ProductHitDict } from "../../generated";
import { LogoSizes } from "../../library/Logo";
import Tag, { TagVariants } from "../Tag";
import ContractCTASection from "./ContractCTASection";
import type { CTA } from "./types";

interface SupplierDetailComponentProps {
  supplierId?: Maybe<number>;
  supplierName: string;
  supplierLogoUrl?: string;
  supplierOfferings: MatchResult[];
  matchTier?: string;
  tags?: ReactNode[];
  ctas?: CTA[];
  loginWallTriggerId?: string;
  loginWallTrigger?: LoginWallTriggers;
  productHits: ProductHitDict[];
}
export default function SupplierDetailComponent({
  supplierId,
  supplierName,
  supplierLogoUrl,
  matchTier,
  supplierOfferings,
  tags,
  ctas,
  loginWallTrigger,
  loginWallTriggerId,
  productHits,
}: SupplierDetailComponentProps) {
  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId,
    supplierName,
  });
  const showSupplierOfferings =
    !!supplierOfferings.length && matchTier !== "EXACT_SUPPLIER";

  return (
    <div className="flex flex-col sm:flex-row w-full">
      <div className="flex flex-col gap-y-3 w-full">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center mr-6 my-auto">
            {supplierLogoUrl ? (
              <Avatar
                className="mr-4"
                size={AvatarSizes.MED_LG}
                logoImageSrc={supplierLogoUrl}
                logoSize={LogoSizes.XS}
                logoAlt={`${supplierName} logo`}
              />
            ) : (
              <Avatar
                className="mr-4"
                size={AvatarSizes.MED_LG}
                bgColor="accent.default.secondary.enabled"
                textColor="accent.leaf.enabled"
                monogram={supplierName[0]}
              />
            )}
            <Typography size="sm" color="brand.bold" emphasis>
              {supplierName}
            </Typography>
            {exactSupplierMatchAlias && (
              <Typography size="sm" color="subtler">
                &nbsp;{`Also known as ${exactSupplierMatchAlias}`}
              </Typography>
            )}
          </div>
          {ctas && (
            <div className="mt-4 sm:mt-0 sm:ml-auto">
              <ContractCTASection
                loginWallTrigger={loginWallTrigger}
                loginWallTriggerId={loginWallTriggerId}
                ctas={ctas}
              />
            </div>
          )}
        </div>
        {!!tags?.length && (
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">{tags}</div>
        )}
        {showSupplierOfferings && supplierOfferings.length > 1 && (
          <div className="flex flex-wrap gap-2 max-h-[26px] overflow-hidden empty:hidden">
            {supplierOfferings.map(({ value }) => (
              <Tag key={value} variant={TagVariants.SUBTLE} size="sm">
                <p>
                  {styleSearchResponseText(value, {
                    highlightClassName: "font-semibold",
                    sentenceCase: true,
                  })}
                </p>
              </Tag>
            ))}
          </div>
        )}
        <ProductSection products={productHits} />
      </div>
    </div>
  );
}
