import AgreementIcon from "../../../img/icons/agreement.svg";
import CheckIcon from "../../../img/icons/check.svg";
import FindIcon from "../../../img/icons/find.svg";

import { PurchaseCategoryEnum, type PurchaseInput } from "./types";

export const DEFAULT_PURCHASE_PATHS_SUBMISSION: PurchaseInput = {
  budget: { minAmount: null, maxAmount: null },
  category: PurchaseCategoryEnum.COMMODITY,
  purchaseQuery: "",
};

export enum PurchasePathLoadingSteps {
  POLICIES = "POLICIES",
  SUPPLIERS_CONTRACTS = "SUPPLIERS_CONTRACTS",
  COMPLIANCE = "COMPLIANCE",
}

export const LOADING_INTERVAL = 1500;

export const LOADING_STEPS: Record<
  PurchasePathLoadingSteps,
  {
    text: (agencyName: string) => string;
    // biome-ignore lint/suspicious/noExplicitAny: No need to apply types to inline svg elements.
    icon: any;
  }
> = {
  [PurchasePathLoadingSteps.POLICIES]: {
    text: (agencyName: string) => `Checking ${agencyName} purchasing policies`,
    icon: CheckIcon,
  },
  [PurchasePathLoadingSteps.SUPPLIERS_CONTRACTS]: {
    text: () => "Finding suppliers and contracts that match your need",
    icon: FindIcon,
  },
  [PurchasePathLoadingSteps.COMPLIANCE]: {
    text: () => "Checking contract compliance",
    icon: AgreementIcon,
  },
};

export const LOADING_STEPS_ORDER = Object.keys(
  LOADING_STEPS
) as PurchasePathLoadingSteps[];

export const LOADING_PAGE_DELAY = LOADING_INTERVAL * LOADING_STEPS_ORDER.length;
