import type { ReactNode } from "react";
import type { FormFieldProps } from "../../library/form/types";

// Data
export enum PurchaseCategoryEnum {
  // TODO: Resolve with autogenerated definition
  COMMODITY = "COMMODITY",
  SERVICES = "SERVICES",
  TEXTBOOKS = "TEXTBOOKS",
  CONSTRUCTION = "CONSTRUCTION",
  CONSTRUCTION_MAINTENANCE = "CONSTRUCTION_MAINTENANCE",
  IT = "IT",
}

export interface PurchaseCategory {
  // We use a Record<string, PurchaseCategory[]> to store this data per BLA.
  // When we transfer to DB, we'll add BLA as a foreign key.
  category: PurchaseCategoryEnum; // purchasing_category_id
  description: string; // description
}

// Inputs
export interface PurchaseInput {
  // What category are you purchasing in? e.g. Commodity
  category: PurchaseCategoryEnum;
  // What are you purchasing? e.g. Office furniture
  purchaseQuery: string;
  // What is your budget?
  budget: BudgetOption;
  // Modifiers (TODO: )
  // useFederalFunds?: boolean;
  // useExistingEntityContract?: boolean;
  // isEmergency?: boolean; // is_emergency
  // isContractRequired?: boolean; // is_contract_required
  // isCoopAllowed?: boolean; // is_coop_allowed
}

export type PurchasePathChecklistItem = { name: string; label: ReactNode };

export type PurchasePathDetails = {
  headline: string;
  description: string;
  cta: string;
  estimatedTime?: string;
  getTasks: (
    category: PurchaseCategoryEnum,
    maxAmount: number | null
  ) => PurchasePathChecklistItem[];
};

/**
 * A `PurchaseOutput` object represents a set of options for a given category and threshold.
 */
export interface PurchaseOutput<T extends string> {
  applicableCategory: PurchaseCategoryEnum; // purchasing_category_id
  description?: string; // description
  thresholdAmount: number | null; // threshold_amount
  additionalDetails?: string;
  recommendedOptions: T[];
  backupOptions: T[];
  forbiddenOptions: T[];
}

export interface PurchasePathAgency {
  purchaseAgencyId: string | null;
  purchaseAgencyDisplayName: string | null;
}

export type EntityIntakeCategories = Partial<
  Record<PurchaseCategoryEnum, string>
>;
export type EntityIntakePathDetails<T extends string> = Record<
  T,
  PurchasePathDetails
>;

export interface EntityIntakeData<T extends string> {
  id: string;
  slug: string;
  displayName: string;
  categories: EntityIntakeCategories;
  outputs: PurchaseOutput<T>[];
  pathDetails: EntityIntakePathDetails<T>;
  questions: FormFieldProps[];
}

export type BudgetOption = {
  minAmount: number | null;
  maxAmount: number | null;
};
