import { useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { usePurchasePathSearch } from "../../hooks/usePurchasePathSearch";
import {
  PurchasePathSteps,
  purchasePathsResultsAtom,
  purchasePathsStepAtom,
  purchasePathsSubmissionAtom,
} from "../../jotai/purchasePaths";
import { PageSection } from "../../library";
import { PurchasePathsHeader } from "./PurchasePathsHeader";
import PurchasePathsPageContent from "./PurchasePathsPageContent";
import { LOADING_PAGE_DELAY } from "./constants";
import { usePurchaseOutputs } from "./data";
import type { PurchaseInput } from "./types";
import { useSetPurchasePathsAgency } from "./useSetPurchasePathsAgency";
import { determinePurchaseCase } from "./utils";

export default function PurchasePathsPage({ slug }: { slug: string }) {
  const setResult = useSetAtom(purchasePathsResultsAtom);
  const [step, setStep] = useAtom(purchasePathsStepAtom);
  const setPurchaseSubmission = useSetAtom(purchasePathsSubmissionAtom);
  const purchaseOutputs = usePurchaseOutputs();
  const purchasePathSearch = usePurchasePathSearch();
  const setPurchasePathsAgency = useSetPurchasePathsAgency();

  useEffect(() => {
    setPurchasePathsAgency(slug);
  }, [slug, setPurchasePathsAgency]);

  useEffect(() => {
    if (step === PurchasePathSteps.LOADING) {
      const timer = setTimeout(() => {
        setStep(PurchasePathSteps.RESULTS);
      }, LOADING_PAGE_DELAY);
      return () => clearTimeout(timer);
    }
  }, [step, setStep]);

  const onSubmit = (values: PurchaseInput) => {
    if (step === PurchasePathSteps.FORM) {
      setStep(PurchasePathSteps.FORM_DETAILS);
      return;
    }
    setPurchaseSubmission(values);
    const result = determinePurchaseCase({
      inputBudgetMax: values.budget.maxAmount,
      inputCategory: values.category,
      purchaseOutputs,
    });
    if (result.recommendedOptions.includes("ENTITY_CONTRACT")) {
      purchasePathSearch(values.purchaseQuery);
    }
    setResult(result);
    setStep(PurchasePathSteps.LOADING);
  };

  return (
    <div className="flex flex-col w-full min-h-[calc(max(800px,100vh))]">
      <PurchasePathsHeader />
      <PageSection className="pt-16 pb-24">
        <PurchasePathsPageContent onSubmit={onSubmit} step={step} />
      </PageSection>
    </div>
  );
}
