import { createRoot } from "react-dom/client";

import { getDOMAnchorById } from "../utils";

import PurchasePathsPage from "../components/PurchasePaths/PurchasePathsPage";
import { PageType, SearchSource } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

interface PurchasePathsProps extends AuthPageProps {
  isSSR?: boolean;
  slug: string;
}
export default function PurchasePaths({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
  slug,
}: PurchasePathsProps) {
  return (
    <Page
      searchSource={SearchSource.PURCHASE_PATHS}
      isAuthenticated={is_authenticated}
      pageType={PageType.QUOTE_REQUEST}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
      showBanner={false}
      showHeader={false}
    >
      <PurchasePathsPage slug={slug} />
    </Page>
  );
}

const container = getDOMAnchorById("intake");
if (container) {
  const root = createRoot(container);
  const slug = container.dataset.slug || "";
  root.render(<PurchasePaths slug={slug} />);
}
