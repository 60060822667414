import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { lazy } from "react";
import useShowModal from "../../hooks/useShowModal";
import {
  purchasePathSupplierCountsAtom,
  purchasePathsResultsAtom,
  purchasePathsSubmissionAtom,
} from "../../jotai/purchasePaths";
import { userZipState } from "../../jotai/user";
import { Badge, Bullet, Button, Link, Typography } from "../../library";
import { goToURL } from "../../utils";
import { borderColorClass, iconColorClass } from "../../utils/colors";
import { modals } from "../../utils/enums";
import { getSearchPath } from "../../utils/format";
import {
  PURCHASE_CATEGORIES_BY_AGENCY,
  PURCHASE_PATH_DETAILS_BY_AGENCY,
} from "./data";
import type { PurchasePathDetails } from "./types";

const TemplateText = lazy(() => import("./TemplateText"));

const SUPPLIER_COUNT_FLOOR = 5;
const SUPPLIER_COUNT_MAX = 50;

function displaySupplierCount(count: number) {
  if (count <= SUPPLIER_COUNT_FLOOR)
    return `${count} ${pluralize("supplier", count)}`;
  if (count > SUPPLIER_COUNT_MAX) return `${SUPPLIER_COUNT_MAX}+ suppliers`;

  const roundedDownCount =
    Math.floor((count - 1) / SUPPLIER_COUNT_FLOOR) * SUPPLIER_COUNT_FLOOR;
  return `${roundedDownCount}+ suppliers`;
}

function ResultCard<T extends string>({
  isRecommended = false,
  details,
  option,
}: {
  isRecommended?: boolean;
  details: PurchasePathDetails;
  option: T;
}) {
  const userZip = useAtomValue(userZipState);
  const supplierCounts = useAtomValue(purchasePathSupplierCountsAtom);

  const { category, purchaseQuery, budget } = useAtomValue(
    purchasePathsSubmissionAtom
  );

  const taskItems = details.getTasks(category, budget.maxAmount);

  async function handleClick() {
    goToURL(
      getSearchPath({
        query: purchaseQuery,
        queryZip: userZip,
        analyticsSearchSource: "searchSource-purchase-paths",
      }).href
    );
  }

  const renderCTA = () => {
    const isCoop = ["COOPERATIVE_CONTRACT", "ENTITY_CONTRACT"].includes(option);

    if (supplierCounts && isCoop && supplierCounts[option] > 0) {
      return `View ${displaySupplierCount(supplierCounts[option])} with contracts`;
    }
    return details.cta;
  };

  return (
    <div
      className={clsx("border rounded-6 p-6 grid gap-6", {
        "border-cp-limeade-700 border-neutral-boldest-enabled": isRecommended,
      })}
    >
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="neutral.boldest.enabled"
      >
        {details.headline}
      </Typography>
      <div className="grid gap-3">
        <Typography size="sm" color="neutral.boldest.enabled">
          <TemplateText text={details.description} />
        </Typography>
        {details.estimatedTime && (
          <div className="flex items-center gap-2">
            <Badge
              includeMargin={false}
              iconClass={iconColorClass.neutral.bold.enabled}
              Icon={ScheduleRoundedIcon}
            />
            <Typography size="sm" color="neutral.boldest.enabled">
              {details.estimatedTime}
            </Typography>
          </div>
        )}
      </div>
      <Button
        onClick={handleClick}
        size={Button.sizes.SMALL}
        className="w-fit"
        theme={
          isRecommended
            ? Button.themes.PRIMARY_DARK
            : Button.themes.SECONDARY_DARK
        }
      >
        {renderCTA()}
      </Button>
      {!!taskItems.length && (
        <>
          <div
            className={clsx("border-t-1", borderColorClass.neutral.default)}
          />
          <Disclosure>
            <DisclosureButton className="flex items-center gap-2">
              <Badge
                Icon={KeyboardArrowDownRounded}
                iconClass={clsx(
                  "ui-open:rotate-180 ui-open:transform",
                  iconColorClass.brand.bold.enabled
                )}
                includeMargin={false}
              />
              <Typography size="sm" color="neutral.boldest.enabled">
                View tasks
              </Typography>
            </DisclosureButton>
            <DisclosurePanel className="grid gap-2">
              <div className="grid gap-6">
                <ul className="grid gap-3 ml-7">
                  {taskItems.map(({ name, label }) => (
                    <Bullet key={name} size="sm">
                      {label}
                    </Bullet>
                  ))}
                </ul>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </>
      )}
    </div>
  );
}

export function PurchasePathsResults({
  agencyId,
  agencyDisplayName,
}: { agencyId: string; agencyDisplayName: string }) {
  const { category } = useAtomValue(purchasePathsSubmissionAtom);
  const result = useAtomValue(purchasePathsResultsAtom);
  const showPurchasePathsMethodsModal = useShowModal(
    modals.PURCHASE_PATHS_METHODS
  );

  if (!result) return null;

  const agencyPurchasePathDetails = PURCHASE_PATH_DETAILS_BY_AGENCY[agencyId];
  const categoryName = PURCHASE_CATEGORIES_BY_AGENCY[agencyId][category];
  if (!categoryName) return null;

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-6">
      <div className="lg:col-span-7 grid gap-8">
        <Typography variant="display" color="neutral.boldest.enabled">
          How to purchase
        </Typography>
        <Typography
          size="sm"
          color="neutral.boldest.enabled"
          className="empty:hidden"
        >
          <TemplateText text={result.description} />
        </Typography>
        {result.recommendedOptions.map((option) => (
          <ResultCard
            key={option}
            isRecommended
            details={agencyPurchasePathDetails[option]}
            option={option}
          />
        ))}
        {!!result.backupOptions?.length &&
          result.backupOptions.map(
            (option) =>
              !!agencyPurchasePathDetails[option] && (
                <ResultCard
                  key={option}
                  details={agencyPurchasePathDetails[option]}
                  option={option}
                />
              )
          )}
        <Typography size="sm" color="neutral.boldest.enabled">
          <Link
            emphasis={false}
            size="sm"
            onClick={() => showPurchasePathsMethodsModal({})}
          >
            Learn more
          </Link>{" "}
          about other purchasing methods at {agencyDisplayName}.
        </Typography>
      </div>
    </div>
  );
}
