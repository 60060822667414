import clsx from "clsx";
import { type ReactNode, useEffect } from "react";

import TruncatedListings from "../../library/CardListings/TruncatedListings";
import { getParam, getRequestID, goToURL } from "../../utils";
import SupplierCard from "../SupplierCard";

import type {
  SourceData,
  TrackClickWithinSupplierCarouselFn,
} from "../../components/SolicitationPage/types";
import type { SupplierCardType } from "../../generated";
import { getSupplierUrlPath } from "../../utils/format";
import LoadingCarousel from "./LoadingCarousel";

interface SupplierCarouselProps {
  // list of suppliers to display
  suppliers: SupplierCardType[];
  // geographical location to display in title
  title: string;
  // source data for analytics
  sourceData: SourceData;
  onRender?: (suppliers: SupplierCardType[]) => void;
  onClick: TrackClickWithinSupplierCarouselFn;
  showCtaInHeader?: boolean;
  cta?: ReactNode;
  isLoading?: boolean;
  className?: string;
  // Number of columns for the carousel. Works for 2 and 3, but may
  // want to test on larger numbers
  cols?: number;
  numInitialListingsShown?: number;
}

// Supplier carousel which displays a list of similar suppliers
export default function SupplierCarousel({
  suppliers,
  title,
  sourceData,
  onRender,
  onClick,
  showCtaInHeader = false,
  cta,
  isLoading = false,
  className,
  cols = 3,
  numInitialListingsShown = 3,
}: SupplierCarouselProps) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run tracking once on mount.
  useEffect(() => {
    onRender?.(suppliers);
  }, []);

  if (isLoading) {
    return <LoadingCarousel title={title || ""} cols={cols} />;
  }

  return (
    <TruncatedListings
      title={title}
      increment={cols}
      ctaText="Browse more suppliers"
      ctaClassName="analytics-see-more-similar-suppliers"
      className={clsx("analytics-similar-supplier", className)}
      dataTestId="similar-suppliers"
      showCtaInHeader={showCtaInHeader}
      initialListingsShown={numInitialListingsShown}
      cta={cta}
      cols={cols}
    >
      {suppliers.map((supplier, itemNumber) => {
        const query = getParam("query", "");
        const link = getSupplierUrlPath(
          supplier.supplier.handle,
          query,
          getRequestID()
        );
        return (
          <SupplierCard
            key={supplier.supplier.handle}
            supplier={supplier}
            onClick={() => {
              onClick({ supplier, rank: itemNumber });
              goToURL(link, {
                sourceComponent: "recommendation",
                itemNumber,
                query,
                pageNavigationSource: sourceData.pageType ?? null,
              });
            }}
            className="analytics-similar-supplier"
          />
        );
      })}
    </TruncatedListings>
  );
}
