import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { purchasePathsAgencyAtom } from "../../jotai/purchasePaths";
import { buyerProfileState, intakeFormEnabledState } from "../../jotai/user";
import { PURCAHSE_PATH_AGENCY_BY_SLUG } from "./data";

export function useSetPurchasePathsAgency() {
  const setPurchasePathAgency = useSetAtom(purchasePathsAgencyAtom);
  const { governmentAgencyId, governmentAffiliationDisplayName } =
    useAtomValue(buyerProfileState);
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);

  const show = useCallback(
    (slug: string) => {
      if (
        governmentAgencyId &&
        governmentAffiliationDisplayName &&
        intakeFormEnabled
      ) {
        setPurchasePathAgency({
          purchaseAgencyId: governmentAgencyId,
          purchaseAgencyDisplayName: governmentAffiliationDisplayName,
        });
        return;
      }

      const agency = PURCAHSE_PATH_AGENCY_BY_SLUG[slug];
      if (agency) {
        setPurchasePathAgency({
          purchaseAgencyId: agency?.purchaseAgencyId,
          purchaseAgencyDisplayName: agency?.purchaseAgencyDisplayName,
        });
      }
    },
    [
      setPurchasePathAgency,
      governmentAgencyId,
      governmentAffiliationDisplayName,
      intakeFormEnabled,
    ]
  );
  return show;
}
