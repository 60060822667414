import type { ReactNode } from "react";
import { Link } from "../../library";
import type { PurchaseCategoryEnum, PurchaseOutput } from "./types";

export function determinePurchaseCase<T extends string>({
  inputBudgetMax,
  inputCategory,
  purchaseOutputs,
}: {
  inputBudgetMax: number | null;
  inputCategory: PurchaseCategoryEnum;
  purchaseOutputs: PurchaseOutput<T>[];
}): PurchaseOutput<T> {
  const categoryOutputs = purchaseOutputs.filter(
    ({ applicableCategory, thresholdAmount }) => {
      if (applicableCategory !== inputCategory) return false;

      return thresholdAmount && inputBudgetMax
        ? thresholdAmount >= inputBudgetMax
        : true;
    }
  );

  const sortedOutputs = categoryOutputs.sort((a, b) => {
    if (a.thresholdAmount && b.thresholdAmount) {
      return a.thresholdAmount - b.thresholdAmount;
    }
    if (a.thresholdAmount) return -1;
    if (b.thresholdAmount) return 1;
    return 0;
  });

  // Find the first case that has a threshold amount greater than the budget
  // If there is no maximum budget, get the last case
  return inputBudgetMax
    ? sortedOutputs[0]
    : sortedOutputs[sortedOutputs.length - 1];
}

export function TaskLink({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  return (
    <Link
      size="sm"
      className="hover:underline"
      underline={false}
      emphasis={false}
      href={href}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </Link>
  );
}
