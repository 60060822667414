import { useAtomValue } from "jotai";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import { formatSearchPageParams } from "../../components/ContractSearch/utils";
import {
  contractSearchParamsState,
  requestIDState,
  searchQueryState,
} from "../../jotai/search";
import { projectContextState } from "../../jotai/user";
import { SearchSource } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";
import { trackSearchFilterToggle } from "../../utils/tracking";
import SearchBar from "../SearchBar";
import SearchDebugInfo from "./SearchDebugInfo";
import AllFiltersPopover from "./SearchResults/AllFiltersPopover";
import SearchFilterHeader from "./SearchResults/SearchFilterHeader";
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsHeader from "./SearchResults/SearchResultsHeader";
import type { OnFilterChangeFn } from "./SearchResults/types";
import Sidebar from "./Sidebar";

function SearchPageContainer({
  header,
  sidebar,
  children,
}: { header?: ReactNode; sidebar: ReactNode; children: ReactNode }) {
  return (
    <div className="contract-search grid gap-8 w-full mt-6 mb-20">
      {header}
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-4 md:gap-x-6 items-start">
        <div className="grid col-start-1 col-span-full lg:col-end-9 gap-6">
          {children}
        </div>
        <div className="col-span-4 hidden xl:col-start-9 space-y-6 lg:block">
          {sidebar}
        </div>
      </div>
    </div>
  );
}

export default function SearchPage() {
  const searchParams = useAtomValue(contractSearchParamsState);
  const query = useAtomValue(searchQueryState);
  const requestID = useAtomValue(requestIDState);
  const project = useAtomValue(projectContextState);
  const params = useMemo(
    () => formatSearchPageParams(searchParams),
    [searchParams]
  );
  const [filters, setFilters] = useState(params.filters || []);

  const onFilterChange: OnFilterChangeFn = (params) =>
    trackSearchFilterToggle({
      ...params,
      query,
      requestID,
    });

  useEffect(() => {
    setFilters(params.filters || []);
  }, [params.filters]);

  if (isFeatureEnabled("projectBrowsing") && project?.id) {
    return (
      <SearchPageContainer sidebar={<Sidebar />}>
        <div className="flex flex-row items-center gap-6">
          <SearchBar
            className="flex-grow"
            searchSource={SearchSource.PROJECTS_SERP_SEARCH}
          />
          <AllFiltersPopover
            filters={filters}
            setFilters={setFilters}
            onFilterChange={onFilterChange}
          />
        </div>
        <SearchResultsHeader params={params}>
          <SearchDebugInfo />
        </SearchResultsHeader>
        <SearchResults />
      </SearchPageContainer>
    );
  }

  return (
    <SearchPageContainer
      header={
        <SearchFilterHeader
          filters={filters}
          setFilters={setFilters}
          onFilterChange={onFilterChange}
        />
      }
      sidebar={<Sidebar />}
    >
      <SearchResultsHeader params={params}>
        <SearchDebugInfo />
      </SearchResultsHeader>
      <SearchResults />
    </SearchPageContainer>
  );
}
