import { useAtomValue } from "jotai";
import type { SupplierCardType } from "../../../generated";
import { useHideSupplierSearch } from "../../../hooks/useHideSupplierSearch";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  contractSearchParamsState,
  contractSearchResponseDataState,
  redirectSearchResponseDataState,
  requestIDState,
  searchQueryState,
  searchResultTypeState,
  showSupplierRedirectState,
  supplierSearchResponseDataState,
} from "../../../jotai/search";
import { Link, Typography } from "../../../library";
import { SearchActions } from "../../../utils/enums";
import { trackHeapEvent } from "../../../utils/tracking";
import { headerText } from "../utils";
import ResultCountText from "./ResultCountText";

export default function ContractResultsHeaderText() {
  const contractSearchResponseData = useAtomValue(
    contractSearchResponseDataState
  );
  const query = useAtomValue(searchQueryState);
  const searchResultType = useAtomValue(searchResultTypeState);
  const supplierResults = searchResultType === "supplier";

  return (
    <div className="grid gap-1 analytics-search-results-header-text">
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.boldest.enabled"
      >
        {supplierResults
          ? `Results for "${query}"`
          : headerText(query, contractSearchResponseData?.agencyData?.agency)}
      </Typography>
      {!supplierResults && <ResultCountText />}
    </div>
  );
}

export function SupplierCategoriesHeaderText() {
  const searchResultType = useAtomValue(searchResultTypeState);
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const redirectResponseData = useAtomValue(redirectSearchResponseDataState);
  const suggestedSearches =
    supplierResponseData?.supplierData?.suggestedSearches;
  const redirectedQuery = contractResponseData?.params?.childQuery;
  if (!suggestedSearches || !redirectResponseData) {
    return null;
  }

  const searchToShow = redirectedQuery || suggestedSearches[0];
  return (
    <div className="grid gap-2 analytics-search-results-header-text">
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.boldest.enabled"
      >
        {searchResultType === "contract"
          ? `Other results for "${searchToShow}"`
          : `Other suppliers that sell "${searchToShow}"`}
      </Typography>
    </div>
  );
}

export function SupplierResultsHeaderText({
  suppliers,
}: { suppliers: SupplierCardType[] }) {
  const showSupplierRedirect = useAtomValue(showSupplierRedirectState);
  const noContracts = suppliers[0].supplierDisplay.numActiveContracts === 0;
  const { query } = useAtomValue(contractSearchParamsState);
  const search = useSearchContractWithParams();
  const requestID = useAtomValue(requestIDState);
  const hideSupplierSearch = useHideSupplierSearch();
  const searchType = useAtomValue(searchResultTypeState);

  return (
    <div className="grid gap-2 analytics-search-results-header-text mr-2">
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.boldest.enabled"
      >
        Top supplier match
      </Typography>
      {(searchType === "contract" || noContracts) &&
        showSupplierRedirect &&
        !!suppliers.length && (
          <Typography size="sm">
            Showing supplier match for &quot;
            {suppliers[0].supplier.displayName}
            &quot;. Search instead for{"  "}
            <Link
              size="sm"
              underline={false}
              emphasis={false}
              onClick={() => {
                trackHeapEvent("supplier-redirect-show-contracts", {
                  query,
                  requestID,
                });
                hideSupplierSearch(query);
                search({
                  newParams: { query },
                  action: SearchActions.SEARCH_CONTRACTS_ONLY,
                });
              }}
            >
              {query} contracts.
            </Link>
          </Typography>
        )}
    </div>
  );
}
