import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import { Button, Typography } from "../../library";
import AccountPage from "../../pages/Account";
import MobileSearchButton from "../../shared/MobileSearchButton";
import PublicEntityImage from "../../shared/PublicEntityImage";
import SearchBar, {
  SearchBarCtaType,
  SearchBarSize,
} from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import ShareableContractImage from "../../shared/ShareableContractImage";
import SuggestedSearches from "../../shared/SuggestedSearches";
import { SearchSource } from "../../utils/enums";
import { goToHomePage } from "./helpers";

interface BuyerSignupWelcomePageProps {
  blaName: string;
}
export default function BuyerSignupWelcomePage({
  blaName,
}: BuyerSignupWelcomePageProps) {
  function closePage() {
    goToHomePage();
  }

  const infoSection = (
    <div className="grid gap-10">
      <div className="grid gap-4">
        <Typography>
          Stop tracking down contracts. Start purchasing faster. Pavilion’s
          technology aims to empower the public sector’s spirit of
          collaboration.
        </Typography>
        <div className="flex items-center gap-2">
          <PublicEntityImage className="w-22 h-22" />
          <Typography variant="meta" color="neutral.bolder.enabled">
            Over 100,000 shareable contracts
            {blaName.length ? ` from entities like ${blaName}` : ""}.
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <ShareableContractImage className="w-22 h-22" />
          <Typography variant="meta" color="neutral.bolder.enabled">
            Over 400+ national, state, and local sources for commodities,
            services and software already on contracts that serve the US.
          </Typography>
        </div>
      </div>
      <Button
        theme={Button.themes.SECONDARY_DARK}
        onClick={goToHomePage}
        className="analytics-buyer-signup-welcome-go-to-profile"
        dataTestId="continue-to-profile-button"
        badgeProps={{
          Icon: ArrowForwardRoundedIcon,
          reverse: true,
        }}
      >
        View your profile
      </Button>
    </div>
  );

  return (
    <AccountPage
      title="Welcome to Pavilion!"
      topSection={infoSection}
      closePage={closePage}
      userCreated={true}
    >
      <div>
        <Typography variant="headline" color="subtle" className="mb-2">
          Find and use contracts
        </Typography>
        <Typography>
          Search contracts from public entities near you and trusted national
          sources, all in one place for free.
        </Typography>
        <div className="w-full">
          <SearchBar
            className="mt-6 hidden md:flex"
            searchSource={SearchSource.BUYER_SIGNUP_WELCOME_PAGE}
            size={SearchBarSize.RESPONSIVE}
            theme={SearchBarThemes.DARK}
            submitCta={SearchBarCtaType.TEXT}
            disambiguate
          />
          <MobileSearchButton
            searchSource={SearchSource.BUYER_SIGNUP_WELCOME_PAGE}
            className="mt-6"
          />
        </div>
        <SuggestedSearches
          className="mt-10"
          searchSource={SearchSource.BUYER_SIGNUP_WELCOME_PAGE}
        />
      </div>
    </AccountPage>
  );
}
