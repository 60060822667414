import { Button, Typography } from "../../library";
import SupportEmailLink from "../../shared/SupportEmailLink";

export default function SupplierConfirmationForm({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <div className="grid gap-8">
      <div className="grid grid-cols-2 gap-6">
        <Button
          className="analytics-supplier-confirmation-supplier whitespace-nowrap"
          onClick={onConfirm}
        >
          Yes, I{"'"}m a supplier
        </Button>
        <Button
          className="analytics-supplier-confirmation-buyer whitespace-nowrap"
          dataTestId="supplier-confirmation-buyer-button"
          onClick={onCancel}
          theme={Button.themes.SECONDARY_DARK}
        >
          No, I{"'"}m a buyer
        </Button>
      </div>
      <div className="text-center">
        <Typography size="sm">
          Not sure? Please reach out to{" "}
          <SupportEmailLink>customer support</SupportEmailLink>.
        </Typography>
      </div>
    </div>
  );
}
