import { useAtomValue } from "jotai";
import { useMemo } from "react";
import useShowModal from "../../hooks/useShowModal";
import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import {
  buyerProfileState,
  profileTypeState,
  requireIntakeFormEnabledState,
} from "../../jotai/user";
import { ProfileType, modals } from "../../utils/enums";
import { getAnalyticsClass, getDefaultHeaderLinks } from "./utils";

export default function useHeaderLinks() {
  const showQuoteModal = useShowModal(modals.QUOTE_REQUEST);
  const profileType = useAtomValue(profileTypeState);
  const supplierEditUrl = useSupplierEditUrl();
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);

  return useMemo(() => {
    if (profileType === ProfileType.SUPPLIER && supplierEditUrl) {
      return [
        {
          label: "Help",
          href: "/about/resources/pavilion-product-walkthrough",
          analyticsClassName: getAnalyticsClass("header-help"),
          target: "_blank",
        },
        {
          label: "Business profile",
          href: supplierEditUrl,
          analyticsClassName: getAnalyticsClass("header-edit-supplier"),
        },
        {
          label: "Contracts",
          href: supplierEditUrl.replace("edit", "edit#contracts"),
          analyticsClassName: getAnalyticsClass("header-manage-contracts"),
        },
      ];
    }

    if (profileType !== ProfileType.BUYER) {
      return getDefaultHeaderLinks({ isMobile: false });
    }

    const links = [
      {
        label: "Help",
        href: "/about/resources/pavilion-product-walkthrough",
        analyticsClassName: getAnalyticsClass("header-help"),
        target: "_blank",
      },
      {
        label: "Get quotes",
        onClick: () => {
          showQuoteModal({});
        },
        analyticsClassName: getAnalyticsClass("header-quote-request"),
      },
      {
        label: "Projects",
        href: "/projects",
        analyticsClassName: getAnalyticsClass("header-projects"),
      },
    ];
    if (requireIntakeForm || !governmentAgency?.id) return links;

    return [
      ...links,
      {
        label: "Entity contracts",
        href: "/entity-contracts",
        analyticsClassName: getAnalyticsClass("header-entity-contracts"),
      },
    ];
  }, [
    profileType,
    governmentAgency,
    showQuoteModal,
    supplierEditUrl,
    requireIntakeForm,
  ]);
}
