import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import useFilteredSearchResults from "../../hooks/search/useFilteredSearchResults";
import {
  entityContractsIsLoadingState,
  entityContractsParamsState,
  entityContractsResponseDataState,
} from "../../jotai/entityContracts";
import {
  expirationFilterState,
  matchedSearchResultCountState,
} from "../../jotai/searchFilters";
import { userZipState } from "../../jotai/user";
import { Card, Link, Table, Typography } from "../../library";
import { getContractUrl } from "../../shared/ContractBase";
import { bgColorClass } from "../../utils/colors";
import { formatDate } from "../../utils/date";
import { isFeatureEnabled } from "../../utils/features";
import { trackEntityContractsSearchContractClick } from "../../utils/tracking";
import EntityContractsSearchSection from "./EntityContractsSearchSection";
import NoContractsBanner from "./NoContractsBanner";
import type { TableRow } from "./types";
import { renderExpirationDate } from "./utils";

export default function EntityContractsTable() {
  const entityContractsResponseData = useAtomValue(
    entityContractsResponseDataState
  );
  const entityContractsIsLoading = useAtomValue(entityContractsIsLoadingState);
  const entityContractsParams = useAtomValue(entityContractsParamsState);
  const filteredSearchResults = useFilteredSearchResults({
    results: entityContractsResponseData?.contractData?.results || [],
    filterLowSimilarityResults: false,
    filterUnresponsiveContacts: false,
    excludeAgency: null,
  });
  const [tableRows, setTableRows] = useState<TableRow[]>([]);

  const matchedSearchResultCount = useAtomValue(matchedSearchResultCountState);

  const expirationFilter = useAtomValue(expirationFilterState);
  const userZip = useAtomValue(userZipState);

  const matchedHits = useMemo(
    () => filteredSearchResults.slice(0, matchedSearchResultCount),
    [filteredSearchResults, matchedSearchResultCount]
  );

  const requestId = entityContractsResponseData?.params?.requestId || "";

  // biome-ignore lint/correctness/useExhaustiveDependencies: Args to getContractUrl are not used in the effect.
  useEffect(() => {
    if (matchedHits.length > 0) {
      const newRows = matchedHits.map((contract) => {
        const contractUrl = getContractUrl(
          contract.solicitationId,
          contract.docid,
          entityContractsParams.query,
          userZip,
          false,
          false,
          expirationFilter,
          []
        );
        const trackingParams = {
          contractId: contract.docid,
          solicitationId: contract.solicitationId,
          supplierId: contract.supplierId,
          supplierHandle: contract.supplierHandle,
          buyerLeadAgencyId: contract.buyerLeadAgencyId,
          buyerLeadAgency: contract.buyerLeadAgency,
          buyerLeadAgencyState: contract.buyerLeadAgencyState,
          query: entityContractsParams.query,
          requestId,
          rank: contract.rank,
          totalResults: matchedHits.length,
        };

        return {
          contractNumber: contract.contractNumber,
          url: contractUrl.toString(),
          title: contract.title,
          suppliers: contract.suppliersToDisplay?.join(", ") || "",
          expirationDate: formatDate(contract.expirationTimestamp),
          trackingParams,
        };
      });
      setTableRows(newRows);
    }
  }, [matchedHits]);

  return (
    <div className="flex flex-col gap-6">
      {!isFeatureEnabled("permissiveEntityContractSearch") &&
        tableRows.length === 0 &&
        !entityContractsIsLoading && <NoContractsBanner />}
      <EntityContractsSearchSection requestID={requestId} />
      {isFeatureEnabled("permissiveEntityContractSearch") &&
        tableRows.length > 0 &&
        !entityContractsIsLoading && (
          <Typography size="sm" color="neutral.bolder.enabled">
            {`${matchedHits.length} ${pluralize("contract", matchedHits.length)} available`}
          </Typography>
        )}
      <div className="mb-20 overflow-x-auto">
        <Table
          data={tableRows}
          className="w-full"
          columns={[
            {
              key: "contractNumber",
              label: "Contract #",
              isSortable: true,
              render: (_v, { contractNumber }) => contractNumber,
            },
            {
              key: "title",
              label: "Contract title",
              isSortable: true,
              render: (_v, { title, trackingParams, url }) => (
                <Link
                  size="sm"
                  href={url}
                  emphasis={false}
                  underline={false}
                  onClick={() =>
                    trackEntityContractsSearchContractClick(trackingParams)
                  }
                >
                  {title}
                </Link>
              ),
            },
            {
              key: "suppliers",
              label: "Supplier",
              isSortable: true,
              render: (_v, { suppliers }) => suppliers,
            },
            {
              key: "expirationDate",
              label: "Expiration date",
              isSortable: true,
              render: (_v, { expirationDate }) =>
                renderExpirationDate(expirationDate),
            },
          ]}
          isLoading={entityContractsIsLoading}
        />
        {isFeatureEnabled("permissiveEntityContractSearch") &&
          matchedHits.length === 0 &&
          !entityContractsIsLoading && (
            <Card
              showShadow={false}
              className="flex flex-col gap-6 p-6 mt-6 h-fit"
              bgColor={bgColorClass.accent.persimmon.subtle}
            >
              <div className="flex justify-between relative">
                <div className="flex flex-col gap-2">
                  <Typography
                    variant="headline"
                    size="xs"
                    color="brand.boldest.enabled"
                    emphasis
                  >
                    No matching contracts found
                  </Typography>
                  <Typography color="neutral.bolder.enabled" size="sm">
                    Try changing your filters or updating your search terms.
                  </Typography>
                </div>
              </div>
            </Card>
          )}
      </div>
    </div>
  );
}
