import { useAtomValue } from "jotai";

import type { ReactNode } from "react";
import PavilionFavicon from "../../../img/icons/pavilion-favicon.svg";
import favicon from "../../../img/meta/favicon-32x32.png";
import {
  goToLoginPage,
  goToSignupPage,
} from "../../components/Account/helpers";
import useShowModal from "../../hooks/useShowModal";
import {
  isAuthenticatedState,
  projectContextState,
  requireIntakeFormEnabledState,
} from "../../jotai/user";
import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  LogoSizes,
} from "../../library";
import { COMPANY_NAME } from "../../utils/constants";
import { SearchSource, SignupOrigin, modals } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";
import { logoPavilion } from "../../utils/img";
import {
  ClickSource,
  trackClickLogin,
  trackClickSignup,
} from "../../utils/tracking";
import MobileSearchButton from "../MobileSearchButton";
import SearchBar, { SearchBarSize } from "../SearchBar";
import { SearchBarThemes } from "../SearchBar/types";
import AuthenticationMenu from "./AuthenticationMenu";
import HamburgerMenu from "./HamburgerMenu";
import HeaderLinkList from "./HeaderLinkList";
import ProfileMenu from "./ProfileMenu";
import ProjectHeader from "./ProjectHeader";
import { MAIN_HEADER_CONTAINER_CLASS } from "./constants";
import useHeaderLinks from "./useHeaderLinks";
import { getDefaultHeaderLinks } from "./utils";

function HeaderContainer({
  left,
  right,
}: { left: ReactNode; right: ReactNode }) {
  return (
    <header className={MAIN_HEADER_CONTAINER_CLASS}>
      <div className="flex flex-row ml-6 lg:ml-8 justify-start items-center w-full gap-6">
        {left}
      </div>
      <div className="mr-4 z-4 w-fit-content flex flex-row items-center gap-6">
        {right}
      </div>
    </header>
  );
}

interface HeaderProps {
  data?: object;
  searchSource?: SearchSource;
  isSSR?: boolean;
  searchInNewTab?: boolean;
  disableAutocomplete?: boolean;
}

export default function Header({
  searchInNewTab = false,
  disableAutocomplete = false,
  searchSource = SearchSource.DEFAULT,
  isSSR = false,
}: HeaderProps) {
  const showSearchModal = useShowModal(modals.SEARCH);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const links = useHeaderLinks();
  const projectContext = useAtomValue(projectContextState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);

  function onEmptyQuery() {
    showSearchModal({
      source: searchSource,
    });
  }

  function signUp(source: ClickSource) {
    trackClickSignup({ triggerType: source });
    goToSignupPage(SignupOrigin.SIGNUP_BUTTON);
  }

  function login(source: ClickSource) {
    trackClickLogin({ triggerType: source });
    goToLoginPage();
  }

  if (projectContext?.id && isFeatureEnabled("projectBrowsing")) {
    return <ProjectHeader />;
  }

  if (requireIntakeForm) {
    return (
      <HeaderContainer
        left={
          <>
            <div className="analytics-logo-link shrink-0">
              <Link href="/welcome" target="_parent" newWindow={false}>
                <Avatar
                  variant={AvatarVariant.PROFILE}
                  size={AvatarSizes.MEDIUM}
                  logoImageSrc={PavilionFavicon}
                  logoSize={LogoSizes.SMALL}
                  logoAlt={`${COMPANY_NAME} logo`}
                />
              </Link>
            </div>
            <HeaderLinkList links={links} />
          </>
        }
        right={
          <>
            <Button
              as={Link}
              theme={ButtonThemes.PRIMARY_DARK}
              size={ButtonSizes.SMALL}
              className="whitespace-nowrap"
              dataTestId="header-new-purchase"
              linkProps={{
                href: "/intake",
                underline: false,
                newWindow: false,
              }}
            >
              New purchase
            </Button>
            <ProfileMenu compact />
          </>
        }
      />
    );
  }

  return (
    <HeaderContainer
      left={
        <>
          {!isAuthenticated && (
            <HamburgerMenu
              onClickSignUp={signUp}
              onClickLogin={login}
              items={getDefaultHeaderLinks({ isMobile: true })}
            />
          )}
          <div className="analytics-logo-link shrink-0">
            <Link
              href={isAuthenticated ? "/welcome" : "/"}
              target="_parent"
              newWindow={false}
            >
              <img
                className="hidden xs:block w-36"
                src={logoPavilion}
                alt={`${COMPANY_NAME} logo`}
              />
              {/*logo height should match the mobile search button height*/}
              <img
                className="block xs:hidden h-[33.5px] rounded-xl"
                src={favicon}
                alt={`${COMPANY_NAME} logo`}
              />
            </Link>
          </div>
          <div className="my-3 ml-4 hidden sm:block w-full">
            <SearchBar
              searchSource={searchSource}
              cbOnEmptyQuery={onEmptyQuery}
              isSSR={isSSR}
              size={SearchBarSize.RESPONSIVE}
              disableAutocomplete={disableAutocomplete}
              searchInNewTab={searchInNewTab}
              theme={SearchBarThemes.LIGHT}
              disambiguate
            />
          </div>
        </>
      }
      right={
        <div className="flex flex-row items-center pr-8 mr-4 sm:pr-0 sm:mr-0">
          <MobileSearchButton
            className="sm:hidden analytics-homepage-searchButton mr-4"
            searchSource={searchSource}
          />
          <HeaderLinkList links={links} />
          {isAuthenticated ? (
            <ProfileMenu />
          ) : (
            <AuthenticationMenu
              onClickSignUp={() => signUp(ClickSource.HEADER)}
              onClickLogin={() => login(ClickSource.HEADER)}
            />
          )}
        </div>
      }
    />
  );
}
