import { useField } from "formik";
import _map from "lodash/map";
import _uniq from "lodash/uniq";

import { useEffect, useMemo } from "react";
import type {
  RadioSelectableChipsFieldProps,
  RadioSelectableChipsOption,
} from "../../library/form/RadioSelectableChipsField";
import RadioSelectableChipsField from "../../library/form/RadioSelectableChipsField";
import { formatDollarAmount } from "../../utils/format";
import { DEFAULT_PURCHASE_PATHS_SUBMISSION } from "./constants";
import { usePurchaseOutputs } from "./data";
import type { BudgetOption, PurchaseInput } from "./types";

function useBudgetOptions(
  name: string
): RadioSelectableChipsOption<BudgetOption>[] {
  const purchaseOutputs = usePurchaseOutputs();
  const [category] = useField<PurchaseInput["category"]>("category");
  const [field, , { setValue }] = useField<PurchaseInput["budget"]>(name);

  const options = useMemo(() => {
    const thresholds = _uniq(
      _map(
        purchaseOutputs.filter(
          ({ applicableCategory }) => applicableCategory === category.value
        ),
        "thresholdAmount"
      )
    ).sort((a, b) => {
      // null thresholds should be last.
      if (a === null) return 1;
      if (b === null) return -1;
      return a - b;
    });

    return thresholds.map((threshold, index) => {
      let minAmount = 0;
      if (index && thresholds[index - 1] !== null) {
        // Safe type assertion since we check for null above.
        minAmount = (thresholds[index - 1] as number) + 1;
      }

      const label = threshold
        ? `${formatDollarAmount(minAmount)} - ${formatDollarAmount(threshold)}`
        : `${formatDollarAmount(minAmount)}+`;
      return {
        key: label,
        value: {
          minAmount,
          maxAmount: threshold,
        },
        label,
      };
    });
  }, [category.value, purchaseOutputs]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Attempt to select the closest budget value when the category changes.
  useEffect(() => {
    if (!category || field.value.minAmount === null) return;
    const option =
      options.find(
        ({ value }) =>
          field.value.minAmount !== null &&
          field.value.minAmount >= value.minAmount &&
          (value.maxAmount === null || field.value.minAmount <= value.maxAmount)
      )?.value || DEFAULT_PURCHASE_PATHS_SUBMISSION.budget;

    setValue(option);
  }, [category]);

  return options;
}

export default function BudgetOptionsField(
  props: Omit<RadioSelectableChipsFieldProps<BudgetOption>, "options">
) {
  const options = useBudgetOptions(props.field.name);
  return <RadioSelectableChipsField {...props} options={options} />;
}
