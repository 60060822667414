import { Typography } from "../../../library";
import { CheckboxField } from "../../../library/form";
import {
  type EntityIntakeCategories,
  type EntityIntakeData,
  PurchaseCategoryEnum,
} from "../types";

const CATEGORIES: EntityIntakeCategories = {
  [PurchaseCategoryEnum.COMMODITY]: "Commodity",
  [PurchaseCategoryEnum.SERVICES]: "Service",
  [PurchaseCategoryEnum.IT]: "Technology",
};

enum PathOption {
  COOPERATIVE_CONTRACT = "COOPERATIVE_CONTRACT",
  ENTITY_CONTRACT = "ENTITY_CONTRACT",
  P_CARD = "P_CARD",
  RFP = "RFP",
  RFQ = "RFQ",
}

const ITD_TASK = {
  name: "itd",
  label: <Typography size="sm">File an ITD Ticket.</Typography>,
};

// TODO: figure out how to only show P-cards if it's recurring.
const data: EntityIntakeData<PathOption> = {
  id: "74476407-a839-4412-a3e2-d826af7d7196",
  slug: "san-jose",
  displayName: "City of San Jose",
  questions: [
    {
      name: "isRecurring",
      component: CheckboxField,
      label: "Is this a recurring purchase?",
    },
  ],
  outputs: [
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      thresholdAmount: 10000,
      recommendedOptions: [
        PathOption.P_CARD,
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      thresholdAmount: 250000,
      recommendedOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [PathOption.RFQ],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      thresholdAmount: null,
      recommendedOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      thresholdAmount: 10000,
      recommendedOptions: [
        PathOption.P_CARD,
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      thresholdAmount: 250000,
      recommendedOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [PathOption.RFQ],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      thresholdAmount: null,
      recommendedOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      thresholdAmount: 10000,
      recommendedOptions: [
        PathOption.P_CARD,
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      thresholdAmount: 250000,
      recommendedOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [PathOption.RFQ],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      thresholdAmount: null,
      recommendedOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      backupOptions: [PathOption.RFP],
      forbiddenOptions: [],
    },
  ],
  categories: CATEGORIES,
  pathDetails: {
    [PathOption.COOPERATIVE_CONTRACT]: {
      headline: "Use a cooperative contract",
      description:
        "You can use existing cooperative contracts that were awarded as a result of a competitive process, for goods or services purchases over $10,000.",
      cta: "View suppliers with contracts",
      estimatedTime: "1-2 weeks to get your need (estimated)",
      getTasks: (category) => {
        const tasks = [
          {
            name: "documentation",
            label: (
              <Typography size="sm">
                Provide a copy of the cooperative solicitation document,
                resulting agreement, and if available, the Notice of Intended
                Award.
              </Typography>
            ),
          },
          {
            name: "scope",
            label: (
              <Typography size="sm">
                Provide a detailed scope of work and/or specifications for the
                products and/or services.
              </Typography>
            ),
          },
          {
            name: "request",
            label: (
              <Typography size="sm">
                Submit an RQ in FMS (Tactical Procurement Guidelines) or a
                request for a new contract (Strategic Procurement Guidelines)
                when instructed by Purchasing.
              </Typography>
            ),
          },
        ];
        if (category !== PurchaseCategoryEnum.IT) return tasks;
        return [ITD_TASK, ...tasks];
      },
    },
    [PathOption.ENTITY_CONTRACT]: {
      headline: "Use an existing San Jose contract",
      description:
        "You can check for an existing City of San Jose contract that matches your need.",
      cta: "View suppliers with contracts",
      estimatedTime: "1-2 weeks (estimated)",
      getTasks: () => [],
    },
    [PathOption.P_CARD]: {
      headline: "Use a p-card or purchase directly",
      description:
        "You can purchase directly for commodities under $10,000 or services under $1,000.",
      cta: "View suppliers",
      estimatedTime: "No wait",
      getTasks: (category) => {
        if (category !== PurchaseCategoryEnum.IT) return [];
        return [ITD_TASK];
      },
    },
    [PathOption.RFP]: {
      headline: "Start a request for proposals (RFP)",
      description:
        "You can start a competitive solicitation for purchases above the formal threshold of $250,000.",
      cta: "Go to Biddingo",
      estimatedTime: "2-4 months or longer (estimated)",
      getTasks: (category) => {
        if (category !== PurchaseCategoryEnum.IT) return [];
        return [ITD_TASK];
      },
    },
    [PathOption.RFQ]: {
      headline: "Use the 3 quotes process",
      description:
        "You can use the 3 quotes process for needs under the formal threshold of $250,000. This option is discouraged for more complex commodities or certain services where comparison is more difficult.",
      cta: "View suppliers",
      estimatedTime: "1-3 weeks to get your need (estimated)",
      getTasks: (category) => {
        if (category !== PurchaseCategoryEnum.IT) return [];
        return [ITD_TASK];
      },
    },
  },
};

export default data;
