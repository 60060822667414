import { useAtomValue } from "jotai";
import {
  PurchasePathSteps,
  purchasePathsAgencyAtom,
  purchasePathsResultsAtom,
} from "../../jotai/purchasePaths";
import { Button, Typography } from "../../library";
import { PurchasePathsForm } from "./PurchasePathsForm";
import PurchasePathsLoading from "./PurchasePathsLoading";
import { PurchasePathsResults } from "./PurchasePathsResults";
import type { PurchaseInput } from "./types";

export default function PurchasePathsPageContent({
  step,
  onSubmit,
}: {
  step: PurchasePathSteps;
  onSubmit: (values: PurchaseInput) => void;
}) {
  const result = useAtomValue(purchasePathsResultsAtom);

  const { purchaseAgencyId, purchaseAgencyDisplayName } = useAtomValue(
    purchasePathsAgencyAtom
  );

  let content = null;

  if (!purchaseAgencyId || !purchaseAgencyDisplayName) {
    return (
      <div className="grid gap-8">
        <Typography variant="headline" color="neutral.boldest.enabled" emphasis>
          Purchasing Paths isn't available for your agency
        </Typography>
        <Typography>Let us know if you're interested!</Typography>
        <Button className="w-fit">I'm interested</Button>
      </div>
    );
  }

  if (step === PurchasePathSteps.LOADING) {
    content = <PurchasePathsLoading />;
  } else if (result && step === PurchasePathSteps.RESULTS) {
    content = (
      <PurchasePathsResults
        agencyId={purchaseAgencyId}
        agencyDisplayName={purchaseAgencyDisplayName}
      />
    );
  } else {
    content = <PurchasePathsForm onSubmit={onSubmit} />;
  }
  return content;
}
