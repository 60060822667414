import clsx from "clsx";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";
import type { ContractSearchParams } from "../../../components/ContractSearch/types";
import {
  allSearchesLoadedState,
  hasMatchedSearchResultsState,
  redirectSearchResponseDataState,
  requestIDState,
  searchResultTypeState,
  showSupplierRedirectState,
  supplierSearchResponseDataState,
} from "../../../jotai/search";
import { getParam } from "../../../utils";
import SupplierMatchSection from "../SupplierMatchSection";
import ContractResultsHeaderText, {
  SupplierCategoriesHeaderText,
  SupplierResultsHeaderText,
} from "./ResultsHeaderText";
import ShareSearchButton from "./ShareSearchButton";

interface SearchResultsHeaderProps {
  params: ContractSearchParams;
  children?: ReactNode;
}
export default function SearchResultsHeader({
  params,
  children,
}: SearchResultsHeaderProps) {
  const searchResultType = useAtomValue(searchResultTypeState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const redirectResponseData = useAtomValue(redirectSearchResponseDataState);
  const showSupplierRedirect = useAtomValue(showSupplierRedirectState);

  const requestID = useAtomValue(requestIDState);

  const suppliers = supplierResponseData?.supplierData?.suppliers || [];
  const fullWidth = getParam("fullWidth");

  // Loading / result state variables
  const anyIsLoading = !useAtomValue(allSearchesLoadedState);
  const hasMatchedSearchResults = useAtomValue(hasMatchedSearchResultsState);
  const supplierMatchExists = suppliers.length > 0;

  // Wait for supplier and contract search to finish loading before rendering
  if (
    anyIsLoading &&
    searchResultType === "supplier" &&
    !showSupplierRedirect
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6 empty:hidden">
      {!anyIsLoading && supplierMatchExists && (
        <>
          <div
            className={clsx("flex justify-between", {
              "max-w-[49.5rem]": fullWidth !== "true",
            })}
          >
            <SupplierResultsHeaderText suppliers={suppliers} />
            <ShareSearchButton />
          </div>
          <SupplierMatchSection
            suppliers={suppliers}
            params={params}
            requestID={requestID}
          />
        </>
      )}
      {!anyIsLoading && redirectResponseData && (
        <SupplierCategoriesHeaderText />
      )}
      {!anyIsLoading && hasMatchedSearchResults && !redirectResponseData && (
        <div
          className={clsx("flex justify-between", {
            "max-w-[49.5rem]": fullWidth !== "true",
          })}
        >
          <ContractResultsHeaderText />
          {!supplierMatchExists && <ShareSearchButton />}
        </div>
      )}
      {children}
    </div>
  );
}
