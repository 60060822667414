import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import type { ContractSearchParams } from "../../components/ContractSearch/types";
import { requestIDState, searchQueryState } from "../../jotai/search";
import { getParam } from "../../utils";
import { trackSearchFilterToggle } from "../../utils/tracking";
import SearchFilterHeader from "./SearchResults/SearchFilterHeader";
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsHeader from "./SearchResults/SearchResultsHeader";

interface WidgetSearchPageProps {
  params: ContractSearchParams;
}

export default function WidgetSearchPage({ params }: WidgetSearchPageProps) {
  const fullWidth = getParam("fullWidth");
  const query = useAtomValue(searchQueryState);
  const [filters, setFilters] = useState(params.filters || []);

  const requestID = useAtomValue(requestIDState);

  useEffect(() => {
    setFilters(params.filters || []);
  }, [params.filters]);

  return (
    <div
      className={clsx("grid gap-8 contract-search w-full mt-6 mb-20", {
        "max-w-[49.5rem]": fullWidth !== "true",
      })}
    >
      <SearchFilterHeader
        filters={filters}
        setFilters={setFilters}
        onFilterChange={(params) => {
          trackSearchFilterToggle({
            ...params,
            query,
            requestID,
          });
        }}
      />
      <SearchResultsHeader params={params} />
      {query && <SearchResults />}
    </div>
  );
}
