import { useAtomValue } from "jotai";
import _map from "lodash/map";
import { lazy } from "react";
import { usePurchasePathDetails } from "../components/PurchasePaths/data";
import { purchasePathsAgencyAtom } from "../jotai/purchasePaths";
import { Button, Typography } from "../library";
import Modal, { modalSizes } from "../shared/Modal/Modal";

const TemplateText = lazy(
  () => import("../components/PurchasePaths/TemplateText")
);

interface PurchasePathsMethodsModalProps {
  hideModal: () => void;
}

export default function PurchasePathsMethodsModal({
  hideModal,
}: PurchasePathsMethodsModalProps) {
  const { purchaseAgencyDisplayName } = useAtomValue(purchasePathsAgencyAtom);
  const purchasePathDetails = usePurchasePathDetails();
  if (!purchaseAgencyDisplayName || !purchasePathDetails) return null;

  return (
    <Modal
      hideModal={hideModal}
      contentClassName="flex flex-col gap-6"
      className="analytics-create-purchase-paths-learn-more-modal"
      modalSize={modalSizes.LARGE}
      ctaSectionClass="flex justify-center"
      ctaSection={
        <Button size={Button.sizes.SMALL} className="w-fit" onClick={hideModal}>
          Okay
        </Button>
      }
    >
      <Typography
        color="neutral.boldest.enabled"
        variant="headline"
        size="sm"
        emphasis
      >
        Purchasing methods at {purchaseAgencyDisplayName}
      </Typography>
      <Typography color="neutral.boldest.enabled" size="sm">
        Other purchasing methods at {purchaseAgencyDisplayName} apply for
        different categories and budget thresholds.
      </Typography>
      {_map(purchasePathDetails, (value, key) => {
        if (!value) return null;
        return (
          <div key={key} className="grid gap-3">
            <Typography
              color="neutral.boldest.enabled"
              variant="headline"
              size="xs"
              emphasis
            >
              {value.headline}
            </Typography>
            <Typography color="neutral.boldest.enabled" size="sm">
              <TemplateText text={value.description} />
            </Typography>
          </div>
        );
      })}
    </Modal>
  );
}
