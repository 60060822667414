import { Field, Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import _map from "lodash/map";
import {
  PurchasePathSteps,
  purchasePathsStepAtom,
  purchasePathsSubmissionAtom,
} from "../../jotai/purchasePaths";
import { Button, LabelVariant, Typography } from "../../library";
import { DropdownField, LabeledInputField } from "../../library/form";
import type { FormFieldProps } from "../../library/form/types";
import { fieldValidationFn } from "../../library/form/utils";
import yup from "../../utils/yupPhone";
import BudgetOptionsField from "./BudgetOptionsField";
import { useCustomFields, usePurchaseCategories } from "./data";
import type { PurchaseInput } from "./types";

const DEFAULT_PROPS: Partial<FormFieldProps> = {
  size: "sm",
  labelSize: "md",
  labelStyle: LabelVariant.FLOATING_BLOCK,
  labelClassName: "mb-6",
  labelTextVariant: "body",
  labelEmphasis: true,
};

const CATEGORY_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: DropdownField,
  name: "category",
  label: "What category are you purchasing?",
  buttonClassName: "!w-1/2",
  validate: yup.string().required("Please select a purchase category."),
};

const PURCHASE_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: LabeledInputField,
  name: "purchaseQuery",
  label: "What are you purchasing?",
  type: "text",
  placeholder: "Enter a product, service, or a brand",
  validate: yup.string().required("Please enter a purchase description."),
};

const BUDGET_OPTIONS_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: BudgetOptionsField,
  name: "budget",
  label: "What is your budget?",
  validate: yup.object().shape({
    minAmount: yup.number().required("Please select your approximate budget"),
    maxAmount: yup.number().nullable(),
  }),
};

function usePurchaseFormFields(): FormFieldProps[] {
  const step = useAtomValue(purchasePathsStepAtom);
  // Derive options based on thresholdMin and thresholdMax of purchase cases
  const agencyCategories = usePurchaseCategories();
  const questions = useCustomFields();

  const categoryOptions = _map(agencyCategories, (label, categoryEnum) => ({
    key: categoryEnum,
    value: categoryEnum,
    label: label || categoryEnum.toLowerCase(),
  }));

  const fields: FormFieldProps[] = [
    {
      ...CATEGORY_FIELD,
      options: categoryOptions,
    },
    PURCHASE_FIELD,
  ];

  if (step === PurchasePathSteps.FORM_DETAILS) {
    fields.push(
      ...questions.map((q) => ({ ...DEFAULT_PROPS, ...q })),
      BUDGET_OPTIONS_FIELD
    );
  }

  return fields;
}

export function PurchasePathsForm({
  onSubmit,
}: {
  onSubmit: (values: PurchaseInput) => void;
}) {
  const initialValues = useAtomValue(purchasePathsSubmissionAtom);
  const fields = usePurchaseFormFields();
  const validationSchema = fieldValidationFn(fields);
  const step = useAtomValue(purchasePathsStepAtom);

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col gap-12">
      <div className="lg:col-span-4">
        <Typography size="md" variant="display" color="neutral.boldest.enabled">
          Tell us about your purchase
        </Typography>
      </div>
      <div className="lg:col-start-6 lg:col-end-13">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Form>
            <div className="grid gap-8">
              {fields.map((props) => (
                <Field key={props.name} editable={true} {...props} />
              ))}
              <Button size={Button.sizes.SMALL} className="w-fit" type="submit">
                {step === PurchasePathSteps.FORM_DETAILS
                  ? "View purchasing options"
                  : "Next"}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
