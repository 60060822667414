import { atom } from "jotai";
import { DEFAULT_PURCHASE_PATHS_SUBMISSION } from "../components/PurchasePaths/constants";
import type {
  PurchaseInput,
  PurchaseOutput,
  PurchasePathAgency,
} from "../components/PurchasePaths/types";

export const purchasePathsSubmissionAtom = atom<PurchaseInput>(
  DEFAULT_PURCHASE_PATHS_SUBMISSION
);

export const purchasePathsResultsAtom = atom<PurchaseOutput<string> | null>(
  null
);

export const purchasePathSupplierCountsAtom = atom<Record<
  string,
  number
> | null>(null);

export enum PurchasePathSteps {
  FORM = "FORM",
  FORM_DETAILS = "FORM_DETAILS",
  LOADING = "LOADING",
  RESULTS = "RESULTS",
}

export const purchasePathsStepAtom = atom<PurchasePathSteps>(
  PurchasePathSteps.FORM
);

/**
 * If users enter through /intake/:slug, we'll find and store
 * the associated agencyId and agencyDisplayName here.
 *
 * Alternatively, a logged-in user with a supported `governmentAgencyId`
 * will also have their agency's ID and display name stored here.
 */
export const purchasePathsAgencyAtom = atom<PurchasePathAgency>({
  purchaseAgencyId: null,
  purchaseAgencyDisplayName: null,
});
