import { Transition } from "@headlessui/react";
import { ArrowBackRounded } from "@mui/icons-material";
import clsx from "clsx";
import { useAtom } from "jotai";
import {
  PurchasePathSteps,
  purchasePathsStepAtom,
} from "../../jotai/purchasePaths";
import { Badge } from "../../library";
import CloseButton from "../../library/Button/CloseButton";
import { bgColorClass } from "../../utils/colors";
import { goToHomePage } from "../Account/helpers";

const progressClasses: Record<PurchasePathSteps, string> = {
  [PurchasePathSteps.FORM]: "w-1/4",
  [PurchasePathSteps.FORM_DETAILS]: "w-1/2",
  [PurchasePathSteps.LOADING]: "w-3/4",
  [PurchasePathSteps.RESULTS]: "w-full",
};

export function PurchasePathsHeader() {
  const [step, setStep] = useAtom(purchasePathsStepAtom);

  const goBack = () => {
    setStep(PurchasePathSteps.FORM_DETAILS);
  };

  return (
    <div className="flex flex-col top-0 sticky z-1">
      <div
        className={clsx(
          "h-18 flex items-center relative px-8",
          bgColorClass.brand.subtlest.enabled,
          {
            "justify-between": step === PurchasePathSteps.RESULTS,
            "justify-end": step !== PurchasePathSteps.RESULTS,
          }
        )}
      >
        {step === PurchasePathSteps.RESULTS && (
          <Badge
            as="button"
            Icon={ArrowBackRounded}
            label="Back"
            onClick={goBack}
          />
        )}
        <CloseButton
          size="lg"
          color="neutral.boldest.enabled"
          onClose={goToHomePage}
        />
      </div>
      <div className={clsx("h-2", bgColorClass.neutral.subtlest.pressed)}>
        <Transition
          show={true}
          appear={true}
          enter="transition-all duration-500 ease-out"
          enterFrom="w-0"
          enterTo={progressClasses[step]}
        >
          <div
            className={clsx(
              "h-2 transition-all duration-500",
              bgColorClass.brand.bold.enabled,
              progressClasses[step]
            )}
          />
        </Transition>
      </div>
    </div>
  );
}
