import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useMemo } from "react";

import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import {
  buyerProfileState,
  logoutCallback,
  profileTypeState,
  requireIntakeFormEnabledState,
  userState,
} from "../../jotai/user";
import { DropdownMenu, Typography } from "../../library";
import Avatar, { AvatarSizes, AvatarVariant } from "../../library/Avatar";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import { useShowInviteTeamModal } from "../../modals/InviteTeamModal";
import { INVITE_TEAM_SOURCE, ProfileType } from "../../utils/enums";

function useMenuItems() {
  const logOut = useAtomCallback(logoutCallback());
  const profileType = useAtomValue(profileTypeState);
  const supplierEditUrl = useSupplierEditUrl();
  const showInviteTeamModal = useShowInviteTeamModal();
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);

  return useMemo(() => {
    const items: MenuItemProps[] = [
      {
        label: "Home",
        href: "/",
        className: "analytics-homepage-home",
        color: "default",
      },
      {
        label: "Account preferences",
        href: "/profile",
        className: "analytics-homepage-profile",
        color: "default",
      },
    ];
    if (profileType === ProfileType.BUYER) {
      items.push(
        {
          label: "Help",
          href: "/about/resources/pavilion-product-walkthrough",
          className: "analytics-homepage-menu-help block xl:hidden",
          color: "default",
          target: "_blank",
        },
        {
          label: "Invite your team",
          className: "analytics-homepage-menu-buyer-team-invite",
          color: "default",
          onClick: () => {
            showInviteTeamModal({
              inviteSource: INVITE_TEAM_SOURCE.PROFILE_MENU,
            });
          },
        },
        {
          label: "Get Quotes",
          href: "/quote-request",
          className: "analytics-homepage-menu-quote-request block xl:hidden",
          color: "default",
        },
        {
          label: "Projects",
          href: "/projects",
          className: "analytics-homepage-menu-projects block xl:hidden",
          color: "default",
        }
      );
      if (!requireIntakeForm && governmentAgency?.id) {
        items.push({
          label: "Entity Contracts",
          href: "/entity-contracts",
          className: "analytics-homepage-entity-contracts block xl:hidden",
          color: "default",
        });
      }
    } else if (profileType === ProfileType.SUPPLIER) {
      items.push(
        {
          label: "Help",
          href: "/about/resources/pavilion-product-walkthrough",
          className: "analytics-homepage-menu-help block xl:hidden",
          color: "default",
          target: "_blank",
        },
        {
          label: "Edit business profile",
          className: "analytics-homepage-edit-supplier block xl:hidden",
          color: "default",
          href: supplierEditUrl || "",
        },
        {
          label: "Manage contracts",
          className: "analytics-homepage-manage-contracts block xl:hidden",
          color: "default",
          href: supplierEditUrl?.replace("edit", "manage-contracts") || "",
        }
      );
    }
    items.push({
      label: "Sign out",
      href: "",
      onClick: logOut,
      className: "analytics-homepage-logout cursor-pointer",
    });

    return items;
  }, [
    logOut,
    profileType,
    governmentAgency,
    requireIntakeForm,
    supplierEditUrl,
    showInviteTeamModal,
  ]);
}

export default function ProfileMenu({ compact }: { compact?: boolean }) {
  const items = useMenuItems();
  const { firstName, lastName, buyerProfile, supplier } =
    useAtomValue(userState);

  const initials = `${firstName ? firstName[0] : ""}${lastName ? lastName[0] : ""}`;

  return (
    <div className="analytics-profile-icon sm:relative">
      <DropdownMenu items={items} align="right" responsive>
        <div className="my-5 flex items-center">
          <Avatar
            monogram={initials}
            variant={AvatarVariant.PROFILE}
            size={AvatarSizes.MEDIUM}
            textColor="brand.bold"
          />
          {!compact && (
            <div className="text-left ml-2 max-w-[125px] xl:max-w-[175px] max-[850px]:hidden">
              <Typography variant="meta" emphasis color="brand.bold" truncate>
                {firstName} {lastName}
              </Typography>
              <Typography
                variant="meta"
                size="sm"
                emphasis
                color="subtler"
                truncate
                dataTestId="profile-menu-entity-name"
              >
                {buyerProfile?.governmentAffiliationDisplayName ||
                  supplier?.displayName}
              </Typography>
            </div>
          )}
          <KeyboardArrowDownRoundedIcon className="ml-2 text-cp-body-lg text-cp-lapis-500" />
        </div>
      </DropdownMenu>
    </div>
  );
}
