import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";

import type { ProjectCreationSource } from "../../components/Project/types";
import { ApiService, type ItemTypeEnum, type Project } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import { modalState } from "../../jotai/page";
import { savedProjectState } from "../../jotai/projects";
import { isAuthenticatedState } from "../../jotai/user";
import { Badge, Button, DropdownMenu } from "../../library";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import type { TypographyColor } from "../../library/Typography/types";
import { useShowNewProjectModal } from "../../modals/ProjectModals/NewProjectModal";
import { handleError } from "../../utils/generatedApi";
import { trackOpenSaveToProjectDropdown } from "../../utils/tracking";
import { BUTTON_PROPS } from "../saveToProjectUtils";

export type ShowModalCallbackType = ({
  onClickCta,
}: { onClickCta: () => void }) => void;

interface SaveToProjectButtonProps {
  itemId: string;
  itemType: ItemTypeEnum;
  additionalItems?: MenuItemProps[];
  source: ProjectCreationSource;
  className?: string;
  buttonClassName?: string;
  dataTestId?: string;
  showOutreachModal?: ShowModalCallbackType;
}

export default function SaveToProjectButton({
  itemId,
  itemType,
  additionalItems = [],
  buttonClassName,
  source,
  className,
  dataTestId = "save-to-project",
  showOutreachModal,
}: SaveToProjectButtonProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const currentModal = useAtomValue(modalState);

  const [savedProject, setSavedProject] = useAtom(savedProjectState);
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const showNewProjectModal = useShowNewProjectModal();
  const requireLogin = useLoginWall();

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is intentional so that we re-fetch projects when the current modal changes
  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList();

        // Only show the not archived projects
        const activeProjects = projects.filter(
          (project) => !project.archivedAt
        );
        setUserProjects(activeProjects);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [isAuthenticated, currentModal]);
  const saveProjectItem = useSaveProjectItem(source);

  const onClick = useCallback(async () => {
    if (savedProject?.id) {
      try {
        await ApiService.apiV1ProjectsItemsDestroy(itemId, savedProject.id);
        setSavedProject(null);
      } catch (e) {
        handleError(e);
      }
    }
    trackOpenSaveToProjectDropdown({ source });
    requireLogin({
      onComplete: () => {},
      subtitle: BUTTON_PROPS[itemType].loginWallTitle,
      triggerType: BUTTON_PROPS[itemType].triggerType,
      triggerId: itemId,
    });
  }, [savedProject, setSavedProject, itemId, itemType, requireLogin, source]);

  const items: MenuItemProps[] = userProjects.map(({ id, name }) => {
    return {
      key: id,
      label: name,
      className: "analytics-save-project-dropdown-project-item",
      onClick: () => {
        if (showOutreachModal) {
          showOutreachModal({
            onClickCta: () =>
              saveProjectItem({ projectId: id, itemId, itemType }),
          });
        } else {
          saveProjectItem({ projectId: id, itemId, itemType });
        }
      },
    };
  });

  items.push({
    label: "+ New project",
    className:
      "analytics-save-project-dropdown-add-project font-semibold sm:font-semibold",
    onClick: () => {
      showNewProjectModal({
        source,
        onCreate: (projectId) =>
          saveProjectItem({ projectId, itemId, itemType }),
        itemId,
        itemType,
        showOutreachModal,
      });
    },
    color: "brand.bold.enabled" as TypographyColor,
  });

  items.push(...additionalItems);

  return (
    <div
      className={clsx("sm:relative w-fit", className)}
      data-testid={dataTestId}
    >
      <DropdownMenu
        items={items}
        label="SAVE TO PROJECT"
        responsive
        buttonProps={{
          theme: Button.themes.SECONDARY_DARK,
          size: Button.sizes.SMALL,
          className: clsx(
            "gap-2 h-auto analytics-save-contract",
            buttonClassName
          ),
          onClick,
        }}
      >
        <Badge
          Icon={savedProject?.id ? BookmarkIcon : BookmarkBorderOutlinedIcon}
        >
          {savedProject?.id ? (
            BUTTON_PROPS[itemType].savedCopy
          ) : (
            <>
              {BUTTON_PROPS[itemType].saveCtaCopy}
              <ExpandMoreRoundedIcon className="w-5 h-5 ml-1" />
            </>
          )}
        </Badge>
      </DropdownMenu>
    </div>
  );
}
