import { Field, Radio, RadioGroup } from "@headlessui/react";
import type { FieldInputProps } from "formik";
import _isEqual from "lodash/isEqual";
import type { ElementType, ReactNode } from "react";
import SelectableChip from "../Input/SelectableChip";
import Label, { LabelVariant } from "../Label";
import Typography from "../Typography";
import type { TypographySize } from "../Typography/types";
import type { CustomFormProps, FieldLabelProps } from "./types";

export type RadioSelectableChipsOption<T> = {
  key: string;
  value: T;
  label: string;
  sublabel?: string;
  children?: (props: { checked: boolean; disabled?: boolean }) => ReactNode;
  className?: string;
  dataTestId?: string;
};

export interface RadioSelectableChipsFieldProps<T> extends FieldLabelProps {
  field: FieldInputProps<T>;
  form: CustomFormProps;
  size?: TypographySize;
  label: string;
  sublabel?: string;
  placeholder?: string;
  className?: string;
  dataTestId?: string;
  onChange?: (value: T) => void;
  options: RadioSelectableChipsOption<T>[];
  leadingIcon?: ElementType;
}

export default function RadioSelectableChipsField<T extends {}>({
  field,
  form: { setFieldValue, errors },
  options,
  size,
  label,
  labelEmphasis = true,
  labelSize,
  labelStyle = LabelVariant.FLOATING,
  labelClassName,
  labelTextVariant,
  sublabel,
  leadingIcon,
  onChange,
  className,
}: RadioSelectableChipsFieldProps<T>) {
  return (
    <div className={className}>
      {label && (
        <Label
          htmlFor={field.name}
          variant={labelStyle}
          emphasis={labelEmphasis}
          className={labelClassName}
          size={labelSize}
          textVariant={labelTextVariant}
          sublabel={sublabel}
        >
          {label}
        </Label>
      )}
      <RadioGroup
        by={(a, b) => _isEqual(a, b)}
        value={field.value}
        onChange={(value) => {
          setFieldValue(field.name, value);
          onChange?.(value);
        }}
        className="flex flex-wrap gap-2"
      >
        {options.map(({ key, label, className: optionClassName, value }) => (
          <Field key={key} className={optionClassName}>
            <Radio value={value}>
              {({ checked }) => (
                <SelectableChip
                  type="checkbox"
                  leadingIcon={leadingIcon}
                  size={size}
                  key={key}
                  label={label}
                  selected={checked}
                  name={key}
                />
              )}
            </Radio>
          </Field>
        ))}
      </RadioGroup>
      {errors[field.name] && (
        <div className="mt-2 mr-2 flex gap-1 items-center empty:hidden">
          <Typography size="sm" variant="meta" color="feedback.bold.error">
            {errors[field.name] ? "Please select an option." : ""}
          </Typography>
        </div>
      )}
    </div>
  );
}
