import { Typography } from "../../../library";
import {
  type EntityIntakeCategories,
  type EntityIntakeData,
  type EntityIntakePathDetails,
  PurchaseCategoryEnum,
  type PurchaseOutput,
  type PurchasePathChecklistItem,
} from "../types";
import { TaskLink } from "../utils";

const CATEGORIES: EntityIntakeCategories = {
  [PurchaseCategoryEnum.COMMODITY]: "Goods",
  [PurchaseCategoryEnum.SERVICES]: "Service",
  [PurchaseCategoryEnum.CONSTRUCTION]: "Construction",
};

enum PathOption {
  COOPERATIVE_CONTRACT = "COOPERATIVE_CONTRACT",
  IFB = "IFB",
  ENTITY_CONTRACT = "ENTITY_CONTRACT",
  P_CARD = "P_CARD",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  RFP = "RFP",
  RFQ = "RFQ",
  RFQ_U = "RFQ_U",
  SOLE_SOURCE = "SOLE_SOURCE",
  SINGLE_SOURCE = "SINGLE_SOURCE",
}

const COUNCIL_TASK = {
  name: "council-approval",
  label: (
    <Typography size="sm">
      Obtain council approval (document council-approved motion with council
      date visible).
    </Typography>
  ),
};
const WORKSHEET_TASK = {
  name: "construction-worksheet",
  label: (
    <Typography size="sm">
      Complete the <TaskLink href="">Construction Worksheet</TaskLink> with
      contingency & retention or{" "}
      <TaskLink href="">Executed Copy of the Contract</TaskLink>
      (Contract with all signatures).
    </Typography>
  ),
};
const EXECUTED_CONTRACT_TASK = {
  name: "executed-contract",
  label: (
    <Typography size="sm">
      Complete the <TaskLink href="">Executed Copy of the Contract</TaskLink>{" "}
      (Contract with all signatures).
    </Typography>
  ),
};
const SUMMARY_SCORE_TASK = {
  name: "summary-score",
  label: (
    <Typography size="sm">
      Complete the
      <TaskLink href="">RFP Summary Score Sheet</TaskLink>.
    </Typography>
  ),
};

const PATH_DETAILS: EntityIntakePathDetails<PathOption> = {
  [PathOption.P_CARD]: {
    headline: "Use a p-card",
    description:
      "You can purchase directly from suppliers for goods under $5,000.",
    cta: "View suppliers",
    estimatedTime: "A couple of days (estimated)",
    getTasks: () => [],
  },
  [PathOption.PURCHASE_ORDER]: {
    headline: "Use a purchase order (PO)",
    description: "You can issue a PO for goods or services under $10,000.",
    cta: "View suppliers",
    estimatedTime: "1-3 weeks to get your need (estimated)",
    getTasks: () => [
      {
        name: "recent-quote",
        label: "Obtain 1 recent price quote from a desired vendor.",
      },
    ],
  },
  [PathOption.RFQ]: {
    headline: "Start a request for quotations (RFQ)",
    description:
      "You can use an RFQ for purchases between $10,000-$100,000. You need 3 quotes, and a vendor is chosen is based on the lowest price.",
    cta: "View suppliers",
    estimatedTime: "2-4 weeks to get your need (estimated)",
    getTasks: () => [
      {
        name: "rfq-quote",
        label: (
          <Typography size="sm">
            Complete the <TaskLink href="">Informal Quote Sheet form.</TaskLink>
            .
          </Typography>
        ),
      },
    ],
  },
  [PathOption.IFB]: {
    headline: "Start an invitation for bids (IFB)",
    description:
      "You can use an IFB if you have the exact scope, qualifications, specifications, timeframes, and requirements for your need. This is generally for purchases over $100,000, though exceptions apply.",
    cta: "Open OpenGov",
    estimatedTime: "1-2 months to get your need (estimated)",
    getTasks: (category) => {
      const list: PurchasePathChecklistItem[] = [COUNCIL_TASK];
      if (category === PurchaseCategoryEnum.CONSTRUCTION) {
        list.push(WORKSHEET_TASK, {
          name: "summary-score",
          label: (
            <Typography size="sm">
              Complete the <TaskLink href="">Bid Tabulation</TaskLink> or{" "}
              <TaskLink href="">RFP Summary Score Sheet</TaskLink>.
            </Typography>
          ),
        });
      } else {
        list.push(EXECUTED_CONTRACT_TASK);
      }
      return list;
    },
  },
  [PathOption.RFP]: {
    headline: "Start a request for proposals (RFP)",
    description:
      "You can use an RFP if you have project goals, qualifications, specifications, timeframes, and requirements for your need, and you're looking for the supplier to suggest how they might complete the job. This is generally for purchases over $100,000, though exceptions apply.",
    cta: "Open OpenGov",
    estimatedTime: "3-4 months or longer (estimated)",
    getTasks: (category) => {
      const list: PurchasePathChecklistItem[] = [COUNCIL_TASK];
      if (category === PurchaseCategoryEnum.CONSTRUCTION) {
        list.push(WORKSHEET_TASK, SUMMARY_SCORE_TASK);
      } else {
        list.push(EXECUTED_CONTRACT_TASK);
      }
      return list;
    },
  },
  [PathOption.RFQ_U]: {
    headline: "Start a request for qualifications (RFQu)",
    description:
      "You can use an RFQu if you have project goals and objectives, and you're looking for qualified suppliers to provide the service. This is generally for purchases over $100,000, though exceptions apply.",
    cta: "Open OpenGov",
    estimatedTime: "3-6 months or longer (estimated)",
    getTasks: (category) => {
      const list: PurchasePathChecklistItem[] = [COUNCIL_TASK];
      if (category === PurchaseCategoryEnum.CONSTRUCTION) {
        list.push(WORKSHEET_TASK, SUMMARY_SCORE_TASK);
      } else {
        list.push(EXECUTED_CONTRACT_TASK);
      }
      return list;
    },
  },
  [PathOption.COOPERATIVE_CONTRACT]: {
    headline: "Use a cooperative contract",
    description:
      "For purchases over $10,000, you can skip the bidding process by using existing cooperative contracts that was competitively awarded by other entities and coops.",
    cta: "View suppliers with contracts",
    estimatedTime: "2-4 weeks to get your need (estimated)",
    getTasks: (_, maxAmount) => {
      const list = [
        {
          name: "coop-quote",
          label: (
            <Typography size="sm">
              Complete the{" "}
              <TaskLink href="/">Cooperative Contract Checklist</TaskLink>.
            </Typography>
          ),
        },
      ];
      if (maxAmount === null || maxAmount > 100000) {
        list.push(COUNCIL_TASK, EXECUTED_CONTRACT_TASK);
      }
      return list;
    },
  },
  [PathOption.ENTITY_CONTRACT]: {
    headline: "Use an existing Santa Cruz contract",
    description:
      "You can check for an existing Santa Cruz contract that matches your need.",
    cta: "Check for existing suppliers",
    estimatedTime: "1-2 weeks (estimated)",
    getTasks: () => [],
  },
  [PathOption.SOLE_SOURCE]: {
    headline: "Sole source",
    description: "Used for extenuating circumstances only.",
    cta: "",
    estimatedTime: "",
    getTasks: () => [
      {
        name: "vendor-form",
        label: "Complete the Sole Source Vendor Form.",
      },
    ],
  },
  [PathOption.SINGLE_SOURCE]: {
    headline: "Single source",
    description: "Used for extenuating circumstances only.",
    cta: "",
    estimatedTime: "",
    getTasks: () => [],
  },
};

const OUTPUTS: PurchaseOutput<PathOption>[] = [
  // Goods
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 5000,
    recommendedOptions: [PathOption.P_CARD],
    backupOptions: [PathOption.PURCHASE_ORDER],
    forbiddenOptions: [],
    description:
      "Your $0-$5,000 goods purchase qualifies for a direct p-card purchase. No PO is required.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 10000,
    recommendedOptions: [PathOption.PURCHASE_ORDER],
    backupOptions: [],
    forbiddenOptions: [PathOption.P_CARD],
    description:
      "Your $5,000-$10,000 goods purchase requires a PO to be issued.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 100000,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFQ],
    forbiddenOptions: [PathOption.P_CARD, PathOption.PURCHASE_ORDER],
    description:
      "Your $10,000-$100,000 goods purchase is below the formal threshold. You have several ways to purchase your need.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFP, PathOption.IFB],
    forbiddenOptions: [PathOption.P_CARD, PathOption.PURCHASE_ORDER],
    description:
      "Your $100,000+ goods purchase is above the formal threshold. You have several ways to purchase your need.",
  },

  // Services
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 10000,
    recommendedOptions: [PathOption.PURCHASE_ORDER],
    backupOptions: [],
    forbiddenOptions: [PathOption.P_CARD],
    description: "Your $0-$10,000 service purchase requires a PO to be issued.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 100000,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFQ],
    forbiddenOptions: [PathOption.P_CARD, PathOption.PURCHASE_ORDER],
    description:
      "Your $10,000-$100,000 service purchase is below the formal threshold. You have several ways to purchase your need.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFP, PathOption.IFB, PathOption.RFQ_U],
    forbiddenOptions: [PathOption.P_CARD, PathOption.PURCHASE_ORDER],
    description:
      "Your $100,000+ service purchase is above the formal threshold. You have several ways to purchase your need.",
  },

  // Construction [Waiting for data]
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: 10000,
    recommendedOptions: [PathOption.PURCHASE_ORDER],
    backupOptions: [],
    forbiddenOptions: [PathOption.P_CARD],
    description: "Your $0-$10,000 service purchase requires a PO to be issued.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: 100000,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFQ],
    forbiddenOptions: [PathOption.P_CARD, PathOption.PURCHASE_ORDER],
    description:
      "Your $10,000-$100,000 construction purchase is below the formal threshold. You have several ways to purchase your need.",
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: null,
    recommendedOptions: [PathOption.RFP],
    backupOptions: [PathOption.IFB, PathOption.RFQ_U],
    forbiddenOptions: [
      PathOption.P_CARD,
      PathOption.PURCHASE_ORDER,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    description:
      "Your $100,000+ construction purchase is above the formal threshold. You have several ways to purchase your need.",
  },
];

const data: EntityIntakeData<PathOption> = {
  id: "df51a812-431f-48b4-b47c-daec28d95eee",
  slug: "santa-cruz",
  displayName: "City of Santa Cruz",
  questions: [],
  categories: CATEGORIES,
  outputs: OUTPUTS,
  pathDetails: PATH_DETAILS,
};

export default data;
