import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { purchasePathsAgencyAtom } from "../../jotai/purchasePaths";
import type { FormFieldProps } from "../../library/form/types";
import ACPS_DATA from "./entity-data/alexandria-city-public-schools";
import SJ_DATA from "./entity-data/city-of-san-jose";
import SC_DATA from "./entity-data/city-of-santa-cruz";
import EPISD_DATA from "./entity-data/el-paso-isd";
import SLO_DATA from "./entity-data/san-luis-obispo";
import type {
  EntityIntakeCategories,
  EntityIntakePathDetails,
  PurchaseOutput,
  PurchasePathAgency,
} from "./types";

const INTAKE_DATA = [
  ACPS_DATA,
  SJ_DATA,
  SC_DATA,
  SLO_DATA,
  EPISD_DATA,
] as const;

export const PURCHASE_PATH_AGENCY_IDS = INTAKE_DATA.map(({ id }) => id);

export const PURCAHSE_PATH_AGENCY_BY_SLUG = INTAKE_DATA.reduce(
  (acc, { id, displayName, slug }) => {
    acc[slug] = {
      purchaseAgencyId: id,
      purchaseAgencyDisplayName: displayName,
    };
    return acc;
  },
  {} as Record<string, PurchasePathAgency>
);

export const PURCHASE_CATEGORIES_BY_AGENCY = INTAKE_DATA.reduce(
  (acc, { id, categories }) => {
    acc[id] = categories;
    return acc;
  },
  {} as Record<string, EntityIntakeCategories>
);

export function usePurchaseCategories() {
  const { purchaseAgencyId } = useAtomValue(purchasePathsAgencyAtom);
  if (!purchaseAgencyId) return [];
  return PURCHASE_CATEGORIES_BY_AGENCY[purchaseAgencyId];
}

export const PURCHASE_OUTPUTS_BY_AGENCY = INTAKE_DATA.reduce(
  (acc, { id, outputs }) => {
    acc[id] = outputs;
    return acc;
  },
  {} as Record<string, PurchaseOutput<string>[]>
);

export function usePurchaseOutputs() {
  const { purchaseAgencyId } = useAtomValue(purchasePathsAgencyAtom);
  if (!purchaseAgencyId) return [];
  return PURCHASE_OUTPUTS_BY_AGENCY[purchaseAgencyId];
}

export const PURCHASE_PATH_DETAILS_BY_AGENCY = INTAKE_DATA.reduce(
  (acc, { id, pathDetails }) => {
    acc[id] = pathDetails;
    return acc;
  },
  {} as Record<string, EntityIntakePathDetails<string>>
);

export function usePurchasePathDetails(): EntityIntakePathDetails<string> {
  const { purchaseAgencyId } = useAtomValue(purchasePathsAgencyAtom);
  if (!purchaseAgencyId) return {};
  return PURCHASE_PATH_DETAILS_BY_AGENCY[purchaseAgencyId];
}

export function useCustomFields(): FormFieldProps[] {
  const { purchaseAgencyId } = useAtomValue(purchasePathsAgencyAtom);

  return useMemo(
    () =>
      INTAKE_DATA.find(({ id }) => id === purchaseAgencyId)?.questions || [],
    [purchaseAgencyId]
  );
}
