import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import type { User } from "../generated/models/User";
import useSendEmailVerification from "../hooks/useSendEmailVerification";
import useShowModal from "../hooks/useShowModal";
import {
  hasDismissedCostToProcureBannerState,
  hasDismissedPurchasingGuideBannerState,
} from "../jotai/history";
import { pageTypeState } from "../jotai/page";
import {
  buyerProfileState,
  isAuthenticatedState,
  profileTypeState,
  userInitializedState,
  userState,
} from "../jotai/user";
import AnimatedPopup, { AnimatedPopupTypes } from "../popups/AnimatedPopup";
import {
  AnnouncementBannerType,
  MODAL_SOURCE,
  PageType,
  ProfileType,
  type ProfileTypes,
  modals,
} from "../utils/enums";
import {
  trackAnnouncementBanner,
  trackClickedPurchasingGuide,
} from "../utils/tracking";

import { Button, Link, Typography } from "../library";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import Tag, { TagVariants } from "../shared/Tag";
import { bgColorClass } from "../utils/colors";
interface BannerProps {
  onDismiss: () => void;
  onClick: () => void;
  hide: () => void;
}

function PurchasingGuideBanner({ onDismiss, onClick }: BannerProps) {
  const { governmentAffiliationDisplayName, governmentAgency } =
    useAtomValue(buyerProfileState);
  const setHasDismissedPurchasingGuideBanner = useSetAtom(
    hasDismissedPurchasingGuideBannerState
  );

  return (
    <AnimatedPopup
      bgColor="brand.boldest.enabled"
      className="flex justify-between top-[5rem] sticky z-3"
      contentClassName="w-full px-5 md:px-30"
      floating={false}
      onClose={() => {
        setHasDismissedPurchasingGuideBanner(true);
        onDismiss();
      }}
      show
      type={AnimatedPopupTypes.CLICK}
      typographyProps={{
        emphasis: true,
        size: "md",
      }}
    >
      <span>
        Unsure if you can use a contract? Learn more about{" "}
        {governmentAffiliationDisplayName}’s cooperative purchasing policy{" "}
        <Link
          className="analytics-purchasing-guide-url"
          color="neutral.subtlest.enabled"
          href={governmentAgency?.purchasingGuide || ""}
          onClick={() => {
            trackClickedPurchasingGuide();
            onClick();
          }}
        >
          here
        </Link>
        .
      </span>
    </AnimatedPopup>
  );
}

function VerifyEmailBanner({ onDismiss, onClick, hide }: BannerProps) {
  const sendEmailVerification = useSendEmailVerification({
    source: MODAL_SOURCE.VERIFY_EMAIL_BANNER,
  });
  const showVerifyEmailModal = useShowModal(modals.EMAIL_VERIFICATION_SUCCESS);

  return (
    <AnimatedPopup
      bgColor="brand.boldest.enabled"
      className="flex justify-between top-[5rem] sticky z-3"
      contentClassName="w-full px-5 md:px-30"
      floating={false}
      onClose={onDismiss}
      type={AnimatedPopupTypes.CLICK}
      show
      typographyProps={{
        emphasis: true,
        size: "md",
      }}
    >
      <div
        className="cursor-pointer"
        onClick={async () => {
          onClick();
          await sendEmailVerification(() =>
            showVerifyEmailModal({ showCta: false })
          );
          hide();
        }}
      >
        Your email is unverified. Verify your email by clicking{" "}
        <span className="underline">here</span> so you can contact suppliers.
      </div>
    </AnimatedPopup>
  );
}

function CostToProcureBanner({ onDismiss, onClick }: BannerProps) {
  const setHasDismissedCostToProcureBanner = useSetAtom(
    hasDismissedCostToProcureBannerState
  );
  return (
    <AnimatedPopup
      bgColor="success.bold.primary.pressed"
      className="flex justify-between top-[5rem] sticky z-3 !py-3"
      contentClassName="w-full m-auto max-w-[75rem]"
      floating={false}
      onClose={() => {
        setHasDismissedCostToProcureBanner(true);
        onDismiss();
      }}
      type={AnimatedPopupTypes.CLICK}
      show
      typographyProps={{
        emphasis: true,
        size: "md",
      }}
    >
      <div className="w-full gap-y-3 gap-x-5 flex flex-col items-start sm:flex-row sm:justify-between sm:items-center">
        <div className="flex flex-col sm:flex-row sm:items-center gap-2">
          <Tag
            variant={TagVariants.NEW_FEATURE_LIMEADE}
            className={bgColorClass.accent.limeade.enabled}
            Icon={AutoAwesomeOutlinedIcon}
          >
            New
          </Tag>
          <Typography
            color="neutral.subtlest.enabled"
            className="text-left "
            emphasis
          >
            Cost to Procure Calculator: Quickly compare the cost of running a
            new solicitation vs piggyback
          </Typography>
        </div>
        <Button
          as={Link}
          size={Button.sizes.SMALL}
          theme={Button.themes.PRIMARY_LIGHT}
          className="whitespace-nowrap"
          linkProps={{ href: "/about/calculator", underline: false }}
          onClick={onClick}
        >
          Compare purchase cost
        </Button>
      </div>
    </AnimatedPopup>
  );
}

interface AnnouncementBannerItem {
  show: (
    user: User,
    {
      hasDismissedPurchasingGuideBanner,
      hasDismissedCostToProcureBanner,
      pageType,
    }: {
      hasDismissedPurchasingGuideBanner: boolean;
      hasDismissedCostToProcureBanner: boolean;
      pageType: Maybe<PageType>;
      profileType: ProfileTypes | null;
      isAuthenticated: boolean;
    }
  ) => boolean;
  Component: React.FC<BannerProps>;
  type: AnnouncementBannerType;
}

const banners: AnnouncementBannerItem[] = [
  {
    show: (user, { isAuthenticated, hasDismissedPurchasingGuideBanner }) =>
      isAuthenticated &&
      !hasDismissedPurchasingGuideBanner &&
      !!user.buyerProfile?.governmentAgency?.purchasingGuide,
    Component: PurchasingGuideBanner,
    type: AnnouncementBannerType.PURCHASING_GUIDE,
  },
  {
    // Show for unverified emails on the home and SERP pages.
    show: (user, { pageType, profileType, isAuthenticated }) =>
      isAuthenticated &&
      !user.emailVerified &&
      profileType === ProfileType.BUYER &&
      !!pageType &&
      [PageType.PROFILE, PageType.CONTRACT_SEARCH].includes(pageType),
    Component: VerifyEmailBanner,
    type: AnnouncementBannerType.VERIFY_EMAIL,
  },
  {
    // Show for unverified emails on the home and SERP pages.
    show: (_, { pageType, hasDismissedCostToProcureBanner }) =>
      !hasDismissedCostToProcureBanner &&
      !!pageType &&
      pageType === PageType.HOME_PAGE,
    Component: CostToProcureBanner,
    type: AnnouncementBannerType.COST_TO_PROCURE_CALCULATOR,
  },
];

function useShowBanner() {
  const isInitialized = useAtomValue(userInitializedState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const user = useAtomValue<User>(userState);
  const profileType = useAtomValue(profileTypeState);
  const pageType = useAtomValue(pageTypeState);
  const hasDismissedPurchasingGuideBanner = useAtomValue(
    hasDismissedPurchasingGuideBannerState
  );
  const hasDismissedCostToProcureBanner = useAtomValue(
    hasDismissedCostToProcureBannerState
  );

  if (!isInitialized) return null;

  return banners.find(({ show }) =>
    show(user, {
      pageType,
      profileType,
      isAuthenticated,
      hasDismissedPurchasingGuideBanner,
      hasDismissedCostToProcureBanner,
    })
  );
}

export default function AnnouncementBanner() {
  const banner = useShowBanner();
  const [show, setShow] = useState(true);

  function hide() {
    setShow(false);
  }

  function onDismiss() {
    if (!banner || !show) return;
    trackAnnouncementBanner({ type: banner.type, action: "dismiss" });
    hide();
  }

  function onClick() {
    if (!banner) return;
    trackAnnouncementBanner({ type: banner.type, action: "click" });
  }

  useEffect(() => {
    if (!banner) return;

    trackAnnouncementBanner({ type: banner.type, action: "show" });
  }, [banner]);

  if (!banner || !show) return null;

  return (
    <banner.Component onDismiss={onDismiss} onClick={onClick} hide={hide} />
  );
}
