import { CheckboxField } from "../../../library/form";
import {
  type EntityIntakeCategories,
  type EntityIntakeData,
  PurchaseCategoryEnum,
} from "../types";

const CATEGORIES: EntityIntakeCategories = {
  [PurchaseCategoryEnum.COMMODITY]: "Goods",
  [PurchaseCategoryEnum.SERVICES]: "Services",
  [PurchaseCategoryEnum.CONSTRUCTION]: "Construction - new build and additions",
  [PurchaseCategoryEnum.CONSTRUCTION_MAINTENANCE]:
    "Construction repair and maintenance",
  [PurchaseCategoryEnum.IT]: "Technology or academic services",
};

enum PathOption {
  SMALL_PURCHASE = "SMALL_PURCHASE",
  COOPERATIVE_CONTRACT = "COOPERATIVE_CONTRACT",
  JOC_COOPERATIVE_CONTRACT = "JOC_COOPERATIVE_CONTRACT",
  ENTITY_CONTRACT = "ENTITY_CONTRACT",
  RFP = "RFP",
  CSP_FOR_CONSTRUCTION = "CSP_FOR_CONSTRUCTION",
}

const DESCRIPTION_TEMPLATE =
  "{% if usingFederalFunds %}Purchases using federal funds require 3 quotes, Sam.gov, and EDGAR forms. {% endif %}" +
  "{% if budget.maxAmount == null %}Purchases over $250,000 require an independent cost estimate." +
  "{% elsif budget.maxAmount <= 250000 %}Purchases over $100,000 require board approval.{% endif %}";

const data: EntityIntakeData<PathOption> = {
  id: "b736bc69-8722-4a44-91b2-768fe8e26884",
  slug: "episd",
  displayName: "El Paso Independent School District",
  questions: [
    {
      name: "usingFederalFunds",
      component: CheckboxField,
      label: "Will this purchase use federal funds?",
    },
  ],
  outputs: [
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      thresholdAmount: 50000,
      recommendedOptions: [PathOption.SMALL_PURCHASE],
      backupOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      thresholdAmount: 100000,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: 250000,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.COMMODITY,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: null,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      thresholdAmount: 50000,
      recommendedOptions: [PathOption.SMALL_PURCHASE],
      backupOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      thresholdAmount: 100000,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: 250000,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.SERVICES,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: null,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      thresholdAmount: 50000,
      recommendedOptions: [PathOption.SMALL_PURCHASE],
      backupOptions: [
        PathOption.ENTITY_CONTRACT,
        PathOption.COOPERATIVE_CONTRACT,
      ],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      thresholdAmount: 100000,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: 250000,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.IT,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: null,
      recommendedOptions: [PathOption.COOPERATIVE_CONTRACT],
      backupOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFP],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.CONSTRUCTION_MAINTENANCE,
      thresholdAmount: 100000,
      recommendedOptions: [PathOption.ENTITY_CONTRACT],
      backupOptions: [PathOption.JOC_COOPERATIVE_CONTRACT],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.CONSTRUCTION_MAINTENANCE,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: 250000,
      recommendedOptions: [PathOption.ENTITY_CONTRACT],
      backupOptions: [PathOption.JOC_COOPERATIVE_CONTRACT],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.CONSTRUCTION_MAINTENANCE,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: null,
      recommendedOptions: [PathOption.ENTITY_CONTRACT],
      backupOptions: [PathOption.JOC_COOPERATIVE_CONTRACT],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
      thresholdAmount: 100000,
      recommendedOptions: [PathOption.ENTITY_CONTRACT],
      backupOptions: [PathOption.CSP_FOR_CONSTRUCTION],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: 250000,
      recommendedOptions: [PathOption.ENTITY_CONTRACT],
      backupOptions: [PathOption.CSP_FOR_CONSTRUCTION],
      forbiddenOptions: [],
    },
    {
      applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
      description: DESCRIPTION_TEMPLATE,
      thresholdAmount: null,
      recommendedOptions: [PathOption.ENTITY_CONTRACT],
      backupOptions: [PathOption.CSP_FOR_CONSTRUCTION],
      forbiddenOptions: [],
    },
  ],
  categories: CATEGORIES,
  pathDetails: {
    [PathOption.SMALL_PURCHASE]: {
      headline: "Make a small purchase directly",
      description:
        "You can purchase directly from suppliers for purchases below $50,000. Small purchases require obtaining quotes from 3 different suppliers if vendor is not on a cooperative or an existing EPISD contract and Commodity Code spend is less than $50,000 district-wide.",
      cta: "View suppliers",
      estimatedTime: "2-3 days (estimated)",
      getTasks: () => [],
    },
    [PathOption.COOPERATIVE_CONTRACT]: {
      headline: "Use a cooperative contract",
      description:
        "You can skip the bidding process by using existing cooperative contracts that were competitively awarded by other entities and coops.{% if category == 'IT' %} Technology and academic services require a needs assessment of more than $25,000.{% endif %}",
      cta: "View suppliers with contracts",
      estimatedTime:
        "2-3 days for simple purchases, 7-15 days for complex projects (estimated)",
      getTasks: () => [],
    },
    [PathOption.JOC_COOPERATIVE_CONTRACT]: {
      headline: "Use a job order contracting (JOC) cooperative contract",
      description:
        "You can skip the bidding process by using existing JOC contracts that were competitively awarded for repair and maintenance. 3 quotes are required for JOC projects.",
      cta: "View suppliers with contracts",
      estimatedTime:
        "7-15 days for simple projects, up to 30 days for complex projects (estimated)",
      getTasks: () => [],
    },
    [PathOption.ENTITY_CONTRACT]: {
      headline: "Use an existing EPISD contract",
      description:
        "You can check for an existing contract that matches your need.",
      cta: "View suppliers with contracts",
      estimatedTime: "1 day (estimated)",
      getTasks: () => [],
    },
    [PathOption.RFP]: {
      headline:
        "Start a request for proposal (RFP) or competitive sealed proposal (CSP)",
      description:
        "You can start a request for proposal or competitive sealed proposal for purchases over $50,000.",
      cta: "View suppliers",
      estimatedTime: "90+ days (estimated)",
      getTasks: () => [],
    },
    [PathOption.CSP_FOR_CONSTRUCTION]: {
      headline: "Start a competitive sealed proposal (CSP)",
      description:
        "You can start a competitive sealed proposal for new build construction and additions.",
      cta: "View suppliers",
      estimatedTime: "90+ days (estimated)",
      getTasks: () => [],
    },
  },
};
export default data;
