import { useEffect, useState } from "react";

import { useAtom } from "jotai";
import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useEntityContracts from "../../hooks/useEntityContracts";
import { entityContractsParamsState } from "../../jotai/entityContracts";
import SearchBar, { SearchBarSize } from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import { ContractDocumentsFilterPopover } from "../../shared/SearchPage/SearchResults/ContractDocumentsFilter";
import { ExpirationFilterPopover } from "../../shared/SearchPage/SearchResults/ExpirationFilter";
import FilterPills from "../../shared/SearchPage/SearchResults/FilterPills";
import type { OnFilterChangeFn } from "../../shared/SearchPage/SearchResults/types";
import { useExpirationFilterProps } from "../../shared/SearchPage/SearchResults/useExpirationFilterProps";
import useResetFiltersAndSearch from "../../shared/SearchPage/SearchResults/useResetFiltersAndSearch";
import useToggleFilterAndSearch from "../../shared/SearchPage/SearchResults/useToggleFiltersAndSearch";
import { getParams, hasWindow } from "../../utils";
import { SearchActions, SearchFilter, SearchSource } from "../../utils/enums";
import { trackSearchFilterToggle } from "../../utils/tracking";

export default function EntityContractsSearchSection({
  requestID = "",
}: {
  requestID: string;
}) {
  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const entityPagePath = hasWindow() ? window.location.pathname : "";
  const [searchParams, setSearchParams] = useAtom(entityContractsParamsState);
  useBindPageParamDict(searchParams, setSearchParams);
  const entityContracts = useEntityContracts();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run on mount.
  useEffect(() => {
    const urlParams = getParams();
    const initialFilters = (
      searchParams?.filters
        ? `${searchParams.filters}`.split(";").filter((f) => !!f)
        : []
    ) as SearchFilter[];
    initialFilters.push(SearchFilter.INCLUDE_NON_COOP);
    setFilters(initialFilters as SearchFilter[]);

    setSearchParams(searchParams);

    entityContracts({
      newParams: {
        query: (urlParams.query as string) || "",
        filters: initialFilters,
      },
      action: SearchActions.SEARCH,
    });
  }, []);

  const onFilterChange: OnFilterChangeFn = (params) => {
    trackSearchFilterToggle({
      ...params,
      query: searchParams?.query,
      requestID,
    });
  };

  const onToggleFilter = useToggleFilterAndSearch({
    filters,
    setFilters,
    onFilterChange,
    onSearch: entityContracts,
  });
  const expirationFilterProps = useExpirationFilterProps({
    onChange: onFilterChange,
    filters,
    onToggleFilter,
  });
  const onReset = useResetFiltersAndSearch(entityContracts, setFilters);

  return (
    <div className="flex flex-col">
      <SearchBar
        className="bla-search-bar w-2/3 mb-4"
        theme={SearchBarThemes.LIGHT}
        size={SearchBarSize.FULL}
        isLocationRelevant={false}
        searchSource={SearchSource.ENTITY_CONTRACTS_PAGE}
        searchUrl={entityPagePath}
        disableAutocomplete
        showExactKeywordsFilter={true}
        onSearch={entityContracts}
      />
      <div>
        <div className="flex flex-wrap sm:flex-no-wrap gap-3">
          <ExpirationFilterPopover {...expirationFilterProps} />
          <ContractDocumentsFilterPopover
            filters={filters}
            onChange={onFilterChange}
            onToggleFilter={onToggleFilter}
          />
        </div>
        <FilterPills
          filters={filters}
          onToggleFilter={onToggleFilter}
          onReset={onReset}
        />
      </div>
    </div>
  );
}
