import pluralize from "pluralize";

import { Avatar, AvatarSizes, Typography } from "../../library";
import GenericCard from "../../library/GenericCard";
import { FileFolderIcon } from "../../shared/CustomIcons/FileFolderIcon";

import type { Project, ProjectCollaborator } from "../../generated";
import { isFeatureEnabled } from "../../utils/features";
import CollaboratorAvatars from "./CollaboratorAvatars";

function Collaborators({
  collaborators,
}: { collaborators: ProjectCollaborator[] }) {
  if (collaborators.length < 2) return null;
  return (
    <div className="mt-4">
      <Typography color="neutral.bold.enabled" size="sm" variant="meta">
        Collaborators
      </Typography>
      <CollaboratorAvatars className="mt-1" collaborators={collaborators} />
    </div>
  );
}

function getCountCopy({
  numContracts,
  numSuppliers,
}: { numContracts: number; numSuppliers: number }) {
  if (numContracts) {
    return `${numContracts} ${pluralize("contract", numContracts)}`;
  }
  if (numSuppliers) {
    return `${numSuppliers} ${pluralize("supplier", numSuppliers)}`;
  }
  return null;
}

export function LoadingProjectCard() {
  return (
    <GenericCard className="!p-4" responsive>
      <div className="h-12 grid gap-y-1">
        <div className="h-4 flex flex-row justify-between">
          <div className="rounded-6 bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse h-full w-1/3" />
          <div className="rounded-6 bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse h-full w-1/3" />
        </div>
        <div className="h-8 rounded-6 bg-gradient-to-br from-neutral-200 to-neutral-100 animate-pulse h-full w-5/3" />
      </div>
    </GenericCard>
  );
}

export function MinimalProjectCard({
  id,
  numContracts,
  numSuppliers,
  startedAt,
  name,
}: Project) {
  const text = getCountCopy({ numContracts, numSuppliers });
  return (
    <GenericCard className="!gap-y-1 !p-4" href={`/projects/${id}`} responsive>
      <div className="flex flex-row justify-between">
        {text && (
          <Typography variant="meta" size="sm" color="neutral.bold.enabled">
            {text}
          </Typography>
        )}
        <Typography variant="meta" size="sm" color="neutral.bold.enabled">
          Started {startedAt}
        </Typography>
      </div>
      <Typography
        variant="headline"
        size="xs"
        color="neutral.boldest.enabled"
        emphasis
      >
        {name}
      </Typography>
    </GenericCard>
  );
}

export default function ProjectCard({
  id,
  name,
  startedAt,
  numContracts,
  numSuppliers,
  collaborators,
}: Project) {
  const itemCopy = getCountCopy({ numContracts, numSuppliers });

  // TODO(sc-26732): Once the new list view is enabled, we don't need this shortcut.
  const href = isFeatureEnabled("projectBrowsing")
    ? `/contract-search?query=${name}&pid=${id}`
    : `/projects/${id}`;

  return (
    <GenericCard className="!flex-row gap-4" href={href} responsive>
      <div className="w-12">
        <Avatar
          size={AvatarSizes.LARGE}
          IconComponent={FileFolderIcon}
          bgColor="accent.leaf.enabled"
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between flex-wrap">
          {itemCopy && (
            <Typography color="neutral.bold.enabled" size="sm" variant="meta">
              {itemCopy}
            </Typography>
          )}
          <Typography color="neutral.bold.enabled" size="sm" variant="meta">
            {startedAt}
          </Typography>
        </div>
        <Typography
          color="brand.default.primary.enabled"
          emphasis
          size="sm"
          variant="headline"
        >
          {name}
        </Typography>
        <Collaborators collaborators={collaborators} />
      </div>
    </GenericCard>
  );
}
