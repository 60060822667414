import {
  type EntityIntakeCategories,
  type EntityIntakeData,
  type EntityIntakePathDetails,
  PurchaseCategoryEnum,
  type PurchaseOutput,
} from "../types";

const CATEGORIES: EntityIntakeCategories = {
  [PurchaseCategoryEnum.COMMODITY]: "Goods",
  [PurchaseCategoryEnum.SERVICES]: "Service",
  [PurchaseCategoryEnum.CONSTRUCTION]: "Construction",
};

enum PathOption {
  SAP = "SAP",
  RFQ_1 = "RQF_1",
  RFQ_2 = "RQF_2",
  RFQ_3 = "RQF_3",
  ITB = "ITB",
  RFP = "RFP",
  AP_INVOICE = "AP_INVOICE",
  INFORMAL_BID = "INFORMAL_BID",
  FORMAL_BID = "FORMAL_BID",
  COOPERATIVE_CONTRACT = "COOPERATIVE_CONTRACT",
  ENTITY_CONTRACT = "ENTITY_CONTRACT",
}

const OUTPUTS: PurchaseOutput<PathOption>[] = [
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 5000,
    recommendedOptions: [PathOption.AP_INVOICE],
    backupOptions: [],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 10000,
    recommendedOptions: [PathOption.ENTITY_CONTRACT, PathOption.SAP],
    backupOptions: [],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: 25000,
    recommendedOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFQ_2],
    backupOptions: [],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.COMMODITY,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.ITB],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 5000,
    recommendedOptions: [PathOption.AP_INVOICE],
    backupOptions: [],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 10000,
    recommendedOptions: [PathOption.ENTITY_CONTRACT, PathOption.SAP],
    backupOptions: [],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 50000,
    recommendedOptions: [PathOption.ENTITY_CONTRACT, PathOption.RFQ_2],
    backupOptions: [],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: 200000,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFQ_3],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.SERVICES,
    thresholdAmount: null,
    recommendedOptions: [
      PathOption.ENTITY_CONTRACT,
      PathOption.COOPERATIVE_CONTRACT,
    ],
    backupOptions: [PathOption.RFP],
    forbiddenOptions: [],
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: 1000,
    recommendedOptions: [PathOption.AP_INVOICE],
    backupOptions: [],
    forbiddenOptions: [PathOption.COOPERATIVE_CONTRACT],
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: 75000,
    recommendedOptions: [PathOption.RFQ_1],
    backupOptions: [],
    forbiddenOptions: [PathOption.COOPERATIVE_CONTRACT],
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: 220000,
    recommendedOptions: [PathOption.INFORMAL_BID],
    backupOptions: [],
    forbiddenOptions: [PathOption.COOPERATIVE_CONTRACT],
  },
  {
    applicableCategory: PurchaseCategoryEnum.CONSTRUCTION,
    thresholdAmount: null,
    recommendedOptions: [PathOption.FORMAL_BID],
    backupOptions: [],
    forbiddenOptions: [PathOption.COOPERATIVE_CONTRACT],
  },
];

const PATH_DETAILS: EntityIntakePathDetails<PathOption> = {
  [PathOption.SAP]: {
    headline: "Submit an SAP Requisition",
    cta: "View suppliers",
    estimatedTime: "1 week (estimated)",
    description:
      "Your department needs to solicit at least one quotation. Your department selects the vendor, and Purchasing awards the vendor via purchase order.",
    getTasks: () => [],
  },
  [PathOption.RFQ_1]: {
    headline: "Submit a Request for Quotation (1 quote)",
    cta: "View suppliers",
    estimatedTime: "1 week (estimated)",
    description:
      "Your department needs to solicit at least one quotation. Your department selects the vendor, and Purchasing awards the vendor via purchase order.",
    getTasks: () => [],
  },
  [PathOption.RFQ_2]: {
    headline: "Submit a Request for Quotation (2 quotes)",
    cta: "View suppliers",
    estimatedTime: "1 week (estimated)",
    description: `Your department needs to solicit at least two quotations. {% if category == 'SERVICES' %}Your department recommends the vendor, and {% endif %}Purchasing awards the vendor via purchase order.`,
    getTasks: () => [],
  },
  [PathOption.RFQ_3]: {
    headline: "Submit a Request for Quotation (3 quotes)",
    cta: "View suppliers",
    estimatedTime: "1 week (estimated)",
    description: `Your department needs to solicit at least three quotations. {% if category == 'SERVICES' %}Your department recommends the vendor, and {% endif %}Purchasing awards the vendor via purchase order.`,
    getTasks: () => [],
  },
  [PathOption.ITB]: {
    headline: "Start an Invitation to Bid (ITB)",
    cta: "View suppliers",
    estimatedTime: "4 weeks (estimated)",
    description:
      "This purchase requires a formal Invitation to Bid (ITB). Purchasing publicly solicits bids and awards via purchase order to the lowest bidder.",
    getTasks: () => [],
  },
  [PathOption.RFP]: {
    headline: "Start an RFP with BOS approval",
    cta: "View suppliers",
    estimatedTime: "6-10 weeks (estimated)",
    description:
      "This purchase requires a Request For Proposal (RFP). Purchasing publicly solicits proposals and your department selects a vendor using a selection committee. A contract is awarded by the Board of Supervisors.",
    getTasks: () => [],
  },
  [PathOption.AP_INVOICE]: {
    headline: "AP Invoice",
    cta: "View suppliers",
    estimatedTime: "No wait",
    description:
      "No Purchasing approval required. Your department places the order and pays using AP invoice.",
    getTasks: () => [],
  },
  [PathOption.INFORMAL_BID]: {
    headline: "Informal Bid",
    cta: "View suppliers",
    estimatedTime: "4-6 weeks (estimated)",
    description:
      "This purchase requires an Informal Bid. Public Works publicly solicits bids and awards a contract to the lowest bidder. Job Order Contract (JOC) is an option for eligible projects.",
    getTasks: () => [],
  },
  [PathOption.FORMAL_BID]: {
    headline: "Formal Bid",
    cta: "View suppliers",
    estimatedTime: "6-10 weeks (estimated)",
    description:
      "Public Works publicly solicits bids and the Board of Supervisors awards the contract. Job Order Contract (JOC) is an option for eligible projects.",
    getTasks: () => [],
  },
  [PathOption.COOPERATIVE_CONTRACT]: {
    headline: "Use a cooperative contract",
    description:
      "You can skip the bidding process by using existing cooperative contracts that were competitively awarded by other entities and coops.",
    cta: "Check for existing suppliers",
    getTasks: () => [],
  },
  [PathOption.ENTITY_CONTRACT]: {
    headline: "Use an existing San Luis Obispo contract",
    description:
      "You can check for an existing San Luis Obispo contract that matches your need.",
    cta: "Check for existing suppliers",
    estimatedTime: "1-2 weeks (estimated)",
    getTasks: () => [],
  },
};

const data: EntityIntakeData<PathOption> = {
  id: "c87d981b-e7c6-4f3b-b14e-f252271a2f5a",
  slug: "san-luis-obispo",
  displayName: "San Luis Obispo County",
  questions: [],
  categories: CATEGORIES,
  outputs: OUTPUTS,
  pathDetails: PATH_DETAILS,
};

export default data;
