import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { PopoverMenu } from "../../../library";
import type { SearchFilter } from "../../../utils/enums";
import AllFilters from "./AllFilters";
import SearchFiltersModalButton from "./SearchFiltersModalButton";
import ToggleDebugButton from "./ToggleDebugButton";
import type { OnFilterChangeFn } from "./types";
import useToggleFilterAndSearch from "./useToggleFiltersAndSearch";

export default function AllFiltersPopover({
  onFilterChange,
  filters,
  setFilters,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
}) {
  const onSearch = useSearchContractWithParams();
  const onToggleFilter = useToggleFilterAndSearch({
    filters,
    setFilters,
    onSearch,
    onFilterChange,
  });

  return (
    <>
      <div className="hidden sm:block">
        <PopoverMenu
          text="Filters"
          variant="primary"
          Icon={TuneRoundedIcon}
          reverse={false}
          noWrap
          noFlip
        >
          {({ close }) => (
            <div className="min-w-[452px] flex flex-col gap-6 my-2">
              <AllFilters
                onFilterChange={onFilterChange}
                onToggleFilter={onToggleFilter}
                onShowResults={close}
                filters={filters}
              />
            </div>
          )}
        </PopoverMenu>
      </div>
      <ToggleDebugButton />
      <SearchFiltersModalButton
        onFilterChange={onFilterChange}
        onToggleFilter={onToggleFilter}
        filters={filters}
      />
    </>
  );
}
