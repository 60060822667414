import { useAtomValue } from "jotai";
import SearchCardSupplierResult from "../../components/ContractSearch/SearchCardSupplierResult";
import type { ContractSearchParams } from "../../components/ContractSearch/types";
import type { SupplierCardType, SupplierSearchResponse } from "../../generated";
import type { MatchResult } from "../../generated/models/MatchResult";
import useDiversityPreferences from "../../hooks/useDiversityPreferences";
import { topSupplierContractCardState } from "../../jotai/search";
import { supplierHasFeature } from "../../utils/featureManagement";
import { SearchResult } from "./SearchResults/SearchResultList";
import { isContractHit } from "./utils";
interface SupplierMatchSectionProps {
  supplierMatchData?: Maybe<SupplierSearchResponse>;
  suppliers: SupplierCardType[];
  params: ContractSearchParams;
  requestID: string;
  supplierCardDataTestId?: string;
}

function SupplierMatchSection({
  suppliers,
  params,
  requestID,
}: SupplierMatchSectionProps) {
  const topSupplierResultCard = useAtomValue(topSupplierContractCardState);
  const diversityPreferences = useDiversityPreferences();

  if (!suppliers?.length) {
    return null;
  }

  if (topSupplierResultCard) {
    return (
      <SearchResult
        key={
          isContractHit(topSupplierResultCard)
            ? topSupplierResultCard.docid
            : topSupplierResultCard.supplierId
        }
        result={topSupplierResultCard}
        query={params.query}
        visibleRank={1}
        primary
        requestID={requestID}
        diversityPreferences={diversityPreferences}
      />
    );
  }

  const supplierWithContractsExists = suppliers.some(
    (supplier) => supplier.supplierDisplay.numActiveContracts
  );
  if (supplierWithContractsExists) {
    suppliers = suppliers.filter(
      (supplier) => supplier.supplierDisplay.numActiveContracts
    );
  }

  return (
    <div className="grid gap-y-4">
      {suppliers.map((supplier) => (
        <SearchCardSupplierResult
          key={supplier.supplier.id}
          hit={{
            rank: 0,
            supplierId: supplier.supplier.id,
            supplierHandle: supplier.supplier.handle,
            supplierDisplayName: supplier.supplier.displayName,
            supplierDiversityCertificationIds:
              supplier.compliance.diversityCertifications.map(
                (cert) => cert.id
              ),
            supplierAbout: supplier.supplier.about || "",
            supplierLogoUrl: supplier.supplier.logoUrl,
            supplierSummary: supplier.supplier.summary || "",
            supplierTags: supplier.compliance.tags,
            supplierHasPotentiallyResponsiveContact: supplierHasFeature(
              supplier.supplierAgreement.activeAgreements,
              "contactResponseTag"
            ),
            supplierOfferings:
              supplier.supplier.offeringsList?.map(
                (o) => ({ value: o }) as MatchResult
              ) || [],
            supplierBrandsAuthorizedForResale: [], // should be empty for a top supplier match
            productHits: [], // should be empty for a top supplier match
            activeShareableContractCount:
              supplier.supplierDisplay.numActiveContracts,
            relevantSuppliers: [],
            supplierAliases: supplier.compliance.aliases,
            supplierAddressCity: supplier.supplier.addressCity || "",
            supplierAddressStateCode: supplier.supplier.addressStateCode || "",
            analyticsTrackIsPro: !!supplier.supplierAgreement.activeAgreements,
          }}
          diversityPreferences={diversityPreferences}
          query={params.query}
          visibleRank={1}
          primary
          requestID={requestID}
        />
      ))}
    </div>
  );
}
export default SupplierMatchSection;
