import clsx from "clsx";
import type { ContractHit } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  Logo,
  LogoSizes,
  PageSection,
  Typography,
} from "../../library";
import { expirationText } from "../../shared/ContractBase";
import MobileSearchButton from "../../shared/MobileSearchButton";
import SearchBar, {
  SearchBarCtaType,
  SearchBarSize,
} from "../../shared/SearchBar";
import { SearchBarThemes } from "../../shared/SearchBar/types";
import { isDistributedAgencyType } from "../../utils";
import { parseDate } from "../../utils/date";
import { elevationClass } from "../../utils/designTokens";
import {
  LoginWallTriggers,
  SearchSource,
  landingPageType,
} from "../../utils/enums";
import { trackLandingPageContractClick } from "../../utils/tracking";
import ContractCard from "../ContractSearch/ContractCard";
import BuyerExplainerSection from "../Home/HomepageComponents/BuyerExplainerSection";
import BuyerFAQ from "../Home/HomepageComponents/BuyerFAQ";
import ScheduleDemoSection from "../Home/HomepageComponents/ScheduleDemoSection";

import { formatNumber } from "../../utils/format";
import type { PageData } from "./LandingPage";
import { getLogoForBLA } from "./helpers";

interface LoggedOutLandingPageProps {
  page: PageData;
}

function isExpired(contract: ContractHit) {
  const expirationDate = parseDate(contract.expirationTimestamp);
  return (expirationDate?.getTime() || 0) < Date.now();
}

export function LoggedOutLandingPage({ page }: LoggedOutLandingPageProps) {
  const requireLogin = useLoginWall();
  const featuredSolicitations = page.initial_list.results
    .filter((c) => !isExpired(c))
    .slice(0, 3);

  const viewAllContracts = () => {
    void requireLogin({
      triggerId: page.buyer_lead_agency_id,
      triggerType: LoginWallTriggers.LANDING_PAGE_VIEW_ALL_CONTRACTS_CLICK,
    });
  };

  const logoUrl = getLogoForBLA(page.buyer_lead_agency_id);

  return (
    <div className="pt-12">
      <PageSection className="xl:py-0">
        <div className="w-full md:max-w-screen-xl flex flex-col gap-1 md:gap-2 lg:w-2/3">
          <div className="flex gap-2 md:gap-4">
            {logoUrl && <Logo imageSrc={logoUrl} size={LogoSizes.XL} />}
            <Typography
              variant="display"
              size="sm"
              color="brand.boldest.enabled"
              className="font-normal"
              component="h1"
            >
              {page.title}
            </Typography>
          </div>
          <Typography
            size="sm"
            component="div"
            color="subtle"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: This prop is the quick-n-dirty way to render an html string provided by the backend.
            dangerouslySetInnerHTML={{ __html: page.subtitle }}
          />
        </div>
      </PageSection>

      <PageSection className="my-8 xl:py-0">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className={clsx(
              "p-6 w-full rounded-6 col-span-2",
              elevationClass["elevation-1"]
            )}
          >
            <Typography
              variant="headline"
              size="sm"
              color="brand.boldest.enabled"
              className="mb-1"
              emphasis
            >
              Find and use contracts from {page.display_name}
              {isDistributedAgencyType(page.membership_type) ? " members" : ""}
            </Typography>
            <Typography
              size="sm"
              color="neutral.bolder.enabled"
              className="mb-4"
            >
              Search contracts from public entities near you and trusted
              national sources, all in one place for free.
            </Typography>
            <SearchBar
              className="w-2/3 hidden md:flex"
              searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE}
              size={SearchBarSize.FULL}
              theme={SearchBarThemes.DARK}
              submitCta={SearchBarCtaType.TEXT}
              buyerLeadAgencyId={page.buyer_lead_agency_id}
              disambiguate
            />
            <MobileSearchButton
              searchSource={SearchSource.LOGGED_OUT_LANDING_PAGE_MODAL}
            />
          </CardContainer>
        </div>
      </PageSection>

      <PageSection>
        {featuredSolicitations.length > 0 ? (
          <>
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              size="sm"
              component="h2"
              className="mb-1"
            >
              Example contracts from {page.display_name}
            </Typography>
            <Typography
              size="sm"
              color="neutral.boldest.enabled"
              className="mb-6"
            >
              Log in or sign up to view all{" "}
              {formatNumber(page.active_shareable_total)} active shareable
              contracts
            </Typography>

            <div className="my-8">
              <div className="w-full grid grid-cols-1 auto-rows-auto md:grid-cols-2 xl:grid-cols-3 md:items-stretch justify-between gap-6">
                {featuredSolicitations.map((solicitation) => {
                  function handleFeaturedSolicitationClick() {
                    trackLandingPageContractClick({
                      solicitationId: solicitation.solicitationId,
                      landingPage: landingPageType.AGENCY,
                      contractId: solicitation.docid,
                      sourceValue: page.source_value,
                    });
                  }

                  return (
                    <ContractCard
                      size="compact"
                      key={solicitation.docid}
                      className="analytics-bla-featured-solicitation"
                      loginWallTriggerId={solicitation.docid}
                      loginWallTrigger={
                        LoginWallTriggers.LANDING_PAGE_CONTRACT_CLICK
                      }
                      onClick={handleFeaturedSolicitationClick}
                      title={solicitation.title}
                      awardedSuppliers={solicitation.suppliersToDisplay}
                      numSuppliers={solicitation.numSuppliers}
                      contractNumber={solicitation.contractNumber}
                      expiration={expirationText(
                        solicitation.expirationTimestamp,
                        {
                          short: true,
                        }
                      )}
                      isExpired={isExpired(solicitation)}
                      buyerLeadAgency={solicitation.buyerLeadAgency}
                      cooperative={solicitation.cooperativeAffiliation || ""}
                    />
                  );
                })}
              </div>
              <div className="flex flex-col items-center mt-8 w-full">
                <Button
                  theme={Button.themes.PRIMARY_DARK}
                  onClick={viewAllContracts}
                  className="analytics-logged-out-bla-sign-up"
                >
                  Sign up
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <Typography
              color="brand.boldest.enabled"
              variant="headline"
              size="sm"
              component="h2"
              className="mb-3"
            >
              Log in or sign up to view all{" "}
              {formatNumber(page.active_shareable_total)} active shareable
              contracts
            </Typography>
            <div className="flex flex-col items-center my-8 w-full">
              <Button
                theme={Button.themes.PRIMARY_DARK}
                onClick={viewAllContracts}
                className="analytics-logged-out-bla-sign-up"
              >
                Sign up
              </Button>
            </div>
          </>
        )}
      </PageSection>

      <PageSection className="bg-cp-violet-200">
        <BuyerExplainerSection />
      </PageSection>

      <PageSection className="bg-cp-violet-100 py-10 flex-grow">
        <BuyerFAQ />
      </PageSection>

      <PageSection className="bg-cp-lapis-500">
        <ScheduleDemoSection analyticsClassName="analytics-landingpage-scheduleDemo" />
      </PageSection>
    </div>
  );
}
